import React from "react";
import "./styles.css";
import { FaWhatsapp } from "react-icons/fa";

export const WhatsApp = () => {
  return (
    <>
      <a className="float" href="https://wa.link/6qf92i" target={"_blank"}>
        <div>
          <FaWhatsapp size={36} color="#fff" />
        </div>
      </a>
    </>
  );
};
