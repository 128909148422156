export const questions = [
  {
    id: 1,
    nombre: "¿Por qué elegir Arthromeds?",
    idCategory: "1",
    categoria: "Servicios",
    desc: "Porque somos una compañía de servicios médicos privados altamente especializada en ofrecer servicios quirúrgicos completos, con precio económico cerrado, sin esperas, sin sorpresas y a coste reducido.",
  },
  {
    id: 2,
    nombre: "¿Por qué nuestro equipo es el más indicado?",
    idCategory: "1",
    categoria: "Servicios",
    desc: "Nuestro equipo está formado por profesionales expertos en el sector sanitario, lo que garantiza la excelencia en el servicio, la atención personalizada y la seguridad médica.",
  },
  {
    id: 3,
    nombre: "¿Cómo empezar el tratamiento con nosotros?",
    idCategory: "1",
    categoria: "Servicios",
    desc: "Contáctanos a través de nuestro correo o whatsapp y agenda una cita con nosotros para que vuelvas a recuperar tu movilidad sin dolor.",
  },
  {
    id: 4,
    nombre: "¿Con qué convenios clínicos contamos?",
    idCategory: "1",
    categoria: "Servicios",
    desc: "ArthroMeds Perú posee convenio con las más importantes clínicas y compañías de seguro, lo que permite optimizar su plan de salud con valores preferenciales en atenciones ambulatorias y hospitalarias.",
  },
  {
    id: 5,
    nombre: "¿Cuáles son las lesiones más comunes de rodilla?",
    idCategory: "3",
    categoria: "Rodilla",
    desc: "Chaqueta vaquero para perros Street Tiger. Inspirada en los brand fashion más de moda del momento para estar siempre a la moda. Máxima comodidad gracias al orificio para correa, al elástico para patas, al cincha elástico y al velcro ajustable.",
  },
  {
    id: 6,
    nombre: "¿Qué hago si me golpeé la rodilla?",
    idCategory: "3",
    categoria: "Rodilla",
    desc: "Lorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum",
  },
  {
    id: 7,
    nombre: "¿Por qué me duele la rodilla?",
    idCategory: "3",
    categoria: "Rodilla",
    desc: "Chaqueta vaquero para perros Street Tiger. Inspirada en los brand fashion más de moda del momento para estar siempre a la moda. Máxima comodidad gracias al orificio para correa, al elástico para patas, al cincha elástico y al velcro ajustable.",
  },
  {
    id: 8,
    nombre: "¿En cuánto tiempo sana un golpe en la rodilla?",
    idCategory: "3",
    categoria: "Rodilla",
    desc: "Chaqueta vaquero para perros Street Tiger. Inspirada en los brand fashion más de moda del momento para estar siempre a la moda. Máxima comodidad gracias al orificio para correa, al elástico para patas, al cincha elástico y al velcro ajustable.",
  },
  {
    id: 9,
    nombre: "¿En qué situación se debe alargar una extremidad?",
    idCategory: "5",
    categoria: "Alargamiento óseo",
    desc: "Tener una dismetría de extremidades (Una pierna más larga que la otra) o tener talla muy inferior al promedio son algunos de los principales motivos por lo que se puede elegir un alargamiento de extremidades, tratamiento quirúrgico que se puede realizar en niños y adultos.",
  },
  {
    id: 10,
    nombre: "¿En qué consiste la cirugía de alargamiento?",
    idCategory: "5",
    categoria: "Alargamiento óseo",
    desc: "En casos de disimetrías, el tratamiento inicial es ortopédico. Se indica el uso de una plantilla o talonera del  tamaño acorde a la desigualdad entre las piernas",
  },
  {
    id: 11,
    nombre: "¿Cuáles son las fases del proceso de elongación del hueso?",
    idCategory: "5",
    categoria: "Alargamiento óseo",
    desc: "El proceso de alargamiento óseo consta de 3 fases: Fase de latencia: Periodo entre el día de la cirugía y el comienzo del alargamiento. Fase de alargamiento: El hueso se va estirando progresivamente. Esto lo realiza el propio en su hogar. Fase de consolidación: Periodo que transcurre entre el cese de la fase de alargamiento y el retiro del tutor.",
  },
  {
    id: 12,
    nombre: "¿Cuáles son los riesgos del alargamiento óseo?",
    idCategory: "5",
    categoria: "Alargamiento óseo",
    desc: "Los riesgos ocurren en un 8 a 12% de los casos aproximadamente. Incluyen retardo de consolidación del hueso, no unión, Infección (como cualquier cirugía), contracturas musculares (por lo que debe realizar terapia física  constantemente), consolidación precoz del hueso elongado y fallas del clavo (rotura del material metálico). La falla del clavo ocurre en 1-3%, lo cual es raro.",
  },
  {
    id: 13,
    nombre: "¿Cuáles son las lesiones más comunes de rodilla?",
    idCategory: "15",
    categoria: "Rodilla",
    desc: "Las lesiones más comunes de la rodilla son las lesiones de meniscos, seguido de la lesión del cartílago y de las lesiones ligamentales. Estas son las lesiones más frecuentes por las cuales llegan los pacientes.",
  },
  {
    id: 14,
    nombre: "¿Qué hago si me golpeé la rodilla?",
    idCategory: "15",
    categoria: "Rodilla",
    desc: "Si te golpeas la rodilla y esta se inflama producto del golpe o del traumatismo, lo recomendable es hacer un reposo relativo y colocarte hielo local por 20 minutos y repetir el procedimiento cada 2 o 3 horas, por 1 o 2 días hasta que desinflame la rodilla. Si el dolor no calma con el hielo local, se puede hacer uso de un analgésico desinflamante. Y si este dolor persiste pese al desinflamante es recomendable buscar un especialista o experto en rodilla para que analice la situación y vea por qué el dolor no quiere irse.",
  },
  {
    id: 15,
    nombre: "¿En cuánto tiempo sana un golpe en la rodilla?",
    idCategory: "15",
    categoria: "Rodilla",
    desc: "El tiempo para recuperarse de un golpe en la rodilla depende de la magnitud del daño que se haya producido en la rodilla.Por ejemplo, yo puedo haberme golpeado y no haber generado nada más que una inflamación periférica de los tejidos blandos, pero si el golpe fue en una determinada ubicación o magnitud más importante, un simple golpe puede llegar a lesionar algún ligamento o algún menisco, incluso el mismo cartílago.",
  },
  {
    id: 16,
    nombre: "¿Por qué me duele la rodilla?",
    idCategory: "15",
    categoria: "Rodilla",
    desc: "Existen muchas causas de dolor de rodilla, puede venir de una causa traumática que es un golpe superficial. Si es un golpe sin trascendencia el dolor va a durar 3 a 5 días y se va ir.También puede doler porque si existe un daño estructural tipo en el menisco o ligamento. Y la característica es que este dolor no se va en el tiempo, al contrario, conforme pase el tiempo este dolor va doliendo más.",
  },
  {
    id: 17,
    nombre: "¿Cuáles son las lesiones más comunes de tobillo?",
    idCategory: "16",
    categoria: "Pie y tobillo",
    desc: "Los problemas más comunes del tobillo son las torceduras y las fracturas (huesos rotos). Una torcedura o esguince es una lesión en los ligamentos. Los esguinces pueden tardar algunas semanas o hasta varios meses en sanar completamente. Una fractura es una ruptura de un hueso.",
  },
  {
    id: 18,
    nombre: "¿Qué hago si me golpeé el pie?",
    idCategory: "16",
    categoria: "Pie y tobillo",
    desc: "En primer lugar, se debe buscar ayuda médica si el dolor no cede después de unas horas, pues esto puede ser señal de un daño severo en algún hueso o estructura del pie. El primer paso a seguir ante un fuerte golpe en el pie es aplicar frío sobre la zona adolorida, ya sea con una bolsa de hielo o un paño humedecido en agua fría. Esto hay que repetirlo cada 2 horas durante las 48 horas siguientes al golpe.",
  },
  {
    id: 19,
    nombre: "¿En cuánto tiempo sana un golpe en el pie y tobillo?",
    idCategory: "16",
    categoria: "Pie y tobillo",
    desc: "El dolor y la hinchazón suelen mejorar 2 a 3 días después de la lesión. En la mayoría de los casos la hinchazón desaparece en 7 días. El dolor puede tardar 2 semanas en desaparecer por completo.",
  },
  {
    id: 20,
    nombre: "¿Por qué me duele el pie y el tobillo?",
    idCategory: "16",
    categoria: "Pie y tobillo",
    desc: "Puede haber inflamación, dolor y rigidez si la articulación del tobillo está esguinzada o si las articulaciones de los dedos del pie están lesionadas. Las lesiones en los pies pueden provocar hematomas y enrojecimiento, que causan dolor al aplicar presión.",
  },
  {
    id: 21,
    nombre: "¿Cuáles son las lesiones más comunes de columna?",
    idCategory: "17",
    categoria: "Columna",
    desc: "La espalda es la parte posterior del cuerpo humano, compuesto por huesos, músculos y tejidos. Su extensión abarca desde el cuello hasta la pelvis. Las lesiones en la espalda pueden ser causadas en el trabajo, realizando deporte o en una sacudida repentina. Las lesiones y el dolor de espalda suelen suceder de forma más habitual en la parte baja de esta. Algunas de las lesiones más frecuentes de espalda son: Lumbalgia, contracturas musculares, escoliosis, hernia discal lumbar o cervical, pinzamiento vertebral o de columna, artrosis de columna y sobrecarga muscular.",
  },
  {
    id: 22,
    nombre: "¿Qué hago si me golpeé la columna?",
    idCategory: "17",
    categoria: "Columna",
    desc: "Busca ayuda. Llama al 911 o a un servicio de emergencia médica. Mantén a la persona inmóvil. Coloca toallas pesadas o sábanas enrolladas a ambos lados del cuello o sostén la cabeza y el cuello para evitar el movimiento. Evita mover la cabeza o el cuello.",
  },
  {
    id: 23,
    nombre: "¿En cuánto tiempo sana un golpe en la columna?",
    idCategory: "17",
    categoria: "Columna",
    desc: "Las magulladuras en la parte baja de la espalda pueden causar dolor e hinchazón. Pero si no hay un daño grave, a menudo mejoran en un período de varios días a algunas semanas.",
  },
  {
    id: 24,
    nombre: "¿Por qué me duele la columna?",
    idCategory: "17",
    categoria: "Columna",
    desc: "Edad. El dolor de espalda es más común a medida que envejeces, a partir de los 30 o 40 años. Falta de ejercicio. Los músculos débiles y no utilizados de la espalda y el abdomen pueden provocar dolor de espalda.\n Sobrepeso. El exceso de peso corporal representa una sobrecarga para la espalda.\n Enfermedades. Algunos tipos de artritis y cáncer pueden contribuir al dolor de espalda.\n Levantar objetos de forma inadecuada. Cuando usas la espalda en lugar de las piernas puedes tener dolor de espalda.\nTrastornos psicológicos. Las personas propensas a la depresión y la ansiedad parecen tener un mayor riesgo de padecer dolor de espalda.",
  },
  {
    id: 25,
    nombre: "¿Cuáles son las lesiones más comunes de mano?",
    idCategory: "18",
    categoria: "Mano y muñeca",
    desc: "Como ya hemos visto, debido al gran uso que le damos a las manos, es normal que en algún momento de nuestra vida suframos alguna patología o lesión. Da igual la edad que tengamos o la actividad que estemos realizando ya que si no cuidamos nuestras manos podemos perder la movilidad y sufrir mucho dolor. Estas son las patologías más frecuentes que podemos llegar a sufrir en las manos: Síndrome del túnel carpiano, tendinitis, fracturas, ruptura de ligamentos y dislocaciones, osteoartritis.",
  },
  {
    id: 26,
    nombre: "¿Qué hago si me golpeé la muñeca?",
    idCategory: "18",
    categoria: "Mano y muñeca",
    desc: "Asegúrese de descansar la muñeca lo más que pueda. Use un vendaje compresivo o una férula para impedir que la muñeca se mueva y reducir la hinchazón.",
  },
  {
    id: 27,
    nombre: "¿En cuánto tiempo sana un golpe en la muñeca?",
    idCategory: "18",
    categoria: "Mano y muñeca",
    desc: "El dolor y la hinchazón pueden ser moderados o fuertes. Los esguinces leves se curan por sí solos con tiempo y reposo. Para los esguinces más serios se necesita una férula o un yeso y tardan 4-6 semanas en curarse.",
  },
  {
    id: 28,
    nombre: "¿Por qué me duele la mano?",
    idCategory: "18",
    categoria: "Mano y muñeca",
    desc: "El dolor de mano puede tener múltiples causas. La mano es un lugar de predilección para las heridas y quemaduras producidas por objetos de trabajo o de uso doméstico. La mayoría de las lesiones graves de la mano se deben a la inexperiencia o por no tomar las debidas precauciones en el uso de máquinas o instrumentos.",
  },
  {
    id: 29,
    nombre: "¿Cuáles son las lesiones más comunes de cadera?",
    idCategory: "19",
    categoria: "Cadera",
    desc: "Ejemplos de lesiones agudas que quizás causen dolor de cadera incluyen: Quebrarse la cadera (fractura de cadera) o la pelvis (fractura pélvica), como una fractura por avulsión. Dislocación de cadera o esguince de cadera. Distensión muscular en la ingle o el glúteo.",
  },
  {
    id: 30,
    nombre: "¿Qué hago si tengo un hematoma en la cadera?",
    idCategory: "19",
    categoria: "Cadera",
    desc: "Un hematoma (contusión) se forma cuando se rompen vasos sanguíneos pequeños y se filtra un poco de sangre en la zona cercana. Los hematomas en la cadera pueden producirse a partir de un golpe, un impacto o una caída. En general, los síntomas de un hematoma incluyen cambios en la coloración de la piel, hinchazón y dolor. Un hematoma profundo puede tardar varias horas en aparecer. Si la lesión es grave, puede que deba hacerse una radiografía para ver si hay algún hueso roto. La inflamación debería reducirse en unos pocos días. Los moretones y el dolor pueden tardar varias semanas en irse.",
  },
  {
    id: 31,
    nombre: "¿En cuánto tiempo sana un golpe en la cadera?",
    idCategory: "19",
    categoria: "Cadera",
    desc: "El dolor y la hinchazón suelen mejorar 2 a 3 días después de la lesión. En la mayoría de los casos la hinchazón desaparece en 7 días. El dolor puede tardar 2 semanas en desaparecer por completo.",
  },
  {
    id: 32,
    nombre: "¿Por qué me duele la cadera?",
    idCategory: "19",
    categoria: "Cadera",
    desc: "El dolor en la cadera puede ser causado por problemas en los huesos o el cartílago de la cadera, incluso: Las fracturas de cadera -- pueden causar dolor repentino y agudo en la cadera. Estas lesiones pueden ser graves y provocar grandes problemas.",
  },
  {
    id: 33,
    nombre: "¿Cuáles son las lesiones más comunes de hombro?",
    idCategory: "20",
    categoria: "Hombro",
    desc: "Las lesiones más frecuentes en el hombro son las lesiones del manguito rotador, las tendinitis (dentro de ellas las tendinitis por causa mecánica y traumatismos, y las tendinitis cálcicas), las luxaciones acromioclaviculares, luxaciones glenohumerales y la artrosis del propio hombro.",
  },
  {
    id: 34,
    nombre: "¿Por qué se disloca el hombro?",
    idCategory: "20",
    categoria: "Hombro",
    desc: "La dislocación del hombro significa una luxación glenohumeral, que es cuando la articulación en la glena con el humero se sale. La principal causa de luxación glenohumeral con las luxaciones traumáticas (un golpe). Por ejemplo, personas que se caen de su bicicleta o personas que están jugando basket y lo golpean de forma brusca y se les sale el hombro.",
  },
  {
    id: 35,
    nombre: "¿En cuánto tiempo tarda en sanar una tendinitis del hombro?",
    idCategory: "20",
    categoria: "Hombro",
    desc: "No existe un tiempo exacto, lo que se sabe es que el sistema humano tiene sus propios mecanismos para desinflamar el hombro, y por tanto el tiempo depende de que tanto se llegó a inflamar el hombro.Puede ser una inflamación leve, moderada o severa. Las inflamaciones severas se dan cuando el hombro es sometido por largo tiempo a un trauma o movimiento repetitivo y estos son el tipo de inflamación que demora más tiempo en recuperarse. Sin embargo, no existe un tiempo exacto, el promedio podría estar entre cuatro días o una semana.",
  },
  {
    id: 36,
    nombre: "¿Qué es una luxación?",
    idCategory: "20",
    categoria: "Hombro",
    desc: "La luxación es la dislocación o la pérdida de congruencia de una articulación.",
  },
  {
    id: 37,
    nombre: "¿Cómo se diagnostican las piernas arqueadas?",
    idCategory: "7",
    categoria: "Corrección de deformidades",
    desc: "En muchos casos, una inspección visual de las piernas es todo lo que se necesita para hacer el diagnóstico. Las radiografías y las resonancias magnéticas pueden producir una imagen más detallada del problema y su gravedad.",
  },
  {
    id: 38,
    nombre: "¿Cómo prevenir las piernas arqueadas?",
    idCategory: "7",
    categoria: "Corrección de deformidades",
    desc: "Idealmente, las piernas arqueadas se evitarían cuando sea posible. Esto significa que el manejo médico y la nutrición adecuadas son fundamentales durante el embarazo y la infancia. Los niños deben tener una dieta con minerales adecuados como calcio y fósforo, y deben recibir alimentos ricos en vitamina D o suplementos de vitamina D. La prevención de la obesidad también es importante en el adulto.",
  },
  {
    id: 39,
    nombre: "¿Cómo vivir con piernas arqueadas?",
    idCategory: "7",
    categoria: "Corrección de deformidades",
    desc: "Es posible vivir con las piernas arqueadas, pero existen algunas desventajas. Para los adultos el arco en la pierna afecta la longitud y la proporción de las piernas. No lucir «normal» puede causar un estigma social, que puede ser difícil de manejar para algunas personas. Con el tiempo, las piernas arqueadas pueden causar otros problemas, como artritis y degeneración de las articulaciones, que provocan dolor. Tener las piernas arqueadas podría impedirle llevar una vida feliz y plena, por las consecuencias antes mencionadas. Si desea corregir la afección, en la clínica Arthromeds podemos ayudarlo.",
  },
  {
    id: 40,
    nombre: "¿Cuál es el procedimiento más común de tratamiento?",
    idCategory: "7",
    categoria: "Corrección de deformidades",
    desc: "El procedimiento más común es una osteotomía tibial. La tibia se corta justo debajo de la rodilla y se le da nueva forma; esto corrige el problema de alineación. La cirugía también puede agregar aproximadamente media pulgada de largo a la pierna.",
  },
  {
    id: 41,
    nombre: "¿Cuándo se realiza una artroscopía?",
    idCategory: "8",
    categoria: "Artroscopía",
    desc: "Se realiza una artroscopia cuando se quiere visualizar el interior de la articulación para confirmar un diagnóstico concreto. También permite tomar muestras y biopsias del interior de la articulación. Pero lo más importante de la artroscopia es poder realizar tratamientos de la articulación.",
  },
  {
    id: 42,
    nombre: "¿Qué tipo de especialistas realizan la cirugía?",
    idCategory: "8",
    categoria: "Artroscopía",
    desc: "Debemos tener en cuenta que, aunque mucho menos invasivo, es un procedimiento quirúrgico como cualquier otro y, por tanto, debe ser desarrollado sólo por cirujanos especialistas en Traumatología y Cirugía Ortopédica. Durante una artroscopia pueden surgir problemas imprevistos. En estos momentos, sólo un cirujano que domine también el resto de las técnicas quirúrgicas de la especialidad podrá llevar a buen término la intervención. En Arthromeds contamos con el equipo y los especialistas en este tipo de cirugías de alta precisión.",
  },
  {
    id: 43,
    nombre: "¿En qué consiste la artroscopía de rodilla?",
    idCategory: "8",
    categoria: "Artroscopía",
    desc: "Es la cirugía en la cual se utiliza una cámara diminuta para observar dentro de la rodilla. Se hacen pequeños cortes para introducir la cámara y pequeños instrumentos quirúrgicos dentro de la rodilla para realizar el procedimiento.",
  },
  {
    id: 44,
    nombre: "¿En qué consiste la artroscopía de hombro?",
    idCategory: "8",
    categoria: "Artroscopía",
    desc: "La artroscopía permite ver el hombro por dentro, haciendo un diagnóstico más preciso de los problemas que ocurran en esa articulación. Además, se puede aplicar directamente un tratamiento quirúrgico puesto que la mayor parte de las estructuras del hombro son accesibles por vía artroscópica.",
  },
  {
    id: 45,
    nombre: "¿Qué es una prótesis articular?",
    idCategory: "9",
    categoria: "Reemplazo articular",
    desc: "Es una cirugía para reemplazar una articulación por una articulación artificial. Esta articulación artificial se llama prótesis. Las prótesis articulares mejoran la capacidad funcional de pacientes con dolor articular.",
  },
  {
    id: 46,
    nombre: "¿Cuándo es necesario usar una prótesis articular?",
    idCategory: "9",
    categoria: "Reemplazo articular",
    desc: "La prótesis articular se recomienda para eliminar el dolor y la incapacidad causada por una gran destrucción articular. Esto se puede deber a que las superficies de la articulación pueden estar dañadas por la artrosis, un proceso que provoca el desgaste del cartílago articular.",
  },
  {
    id: 47,
    nombre: "¿Cuál es el período de recuperación tras una prótesis articular?",
    idCategory: "9",
    categoria: "Reemplazo articular",
    desc: "Varía, dependiendo de las condiciones del paciente. Como norma general, en CECOTEN se le anima para que utilice la articulación poco después de la intervención. En referencia a la prótesis total de rodilla y cadera, el paciente puede colocarse de pie y deambular en pocos días. A su vez, si fuera necesario, se le recomendará ir al médico rehabilitador, el cual le indicará al fisioterapeuta qué tipo de ejercicios son los recomendados para este paciente específico. Además, el fisioterapeuta le podrá instruir en la correcta utilización de muletas.",
  },
  {
    id: 48,
    nombre: "¿Qué beneficios reporta una prótesis articular? ",
    idCategory: "9",
    categoria: "Reemplazo articular",
    desc: "El mayor beneficio de las prótesis articulares es la erradicación del dolor. Es verdad que algunos pacientes notan alguna molestia. La articulación operada está molesta debido a la cirugía. A la vez que los músculos que rodean la articulación están debilitados por la inactividad.",
  },
  {
    id: 49,
    nombre: "¿Qué es una infección ósea?",
    idCategory: "10",
    categoria: "Infección ósea",
    desc: "La infección ósea (ostelomilitis) es una infección que afecta al hueso, causada generalmente por bacterias, mico bacterias u hongos.",
  },
  {
    id: 50,
    nombre: "¿Qué situaciones aumentan el riesgo de tener infección ósea?",
    idCategory: "10",
    categoria: "Infección ósea",
    desc: "Existen diversas situaciones que aumentan la probabilidad de padecer una infección ósea (ostelomilitis). Entre ellas están: diabetes, hemodiálisis, riego sanguíneo deficiente, lesión reciente, consumo de drogas ilícitas inyectadas, cirugía que involucre huesos y sistema inmunitario debilitado.",
  },
  {
    id: 51,
    nombre: "¿Se puede tratar la infección ósea?",
    idCategory: "10",
    categoria: "Infección ósea",
    desc: "Aunque en algún momento se consideraba incurable, en la actualidad la osteomielitis puede tratarse con éxito. La mayoría de las personas necesitan someterse a una cirugía para extirpar las zonas del hueso que murieron. Después de la cirugía, generalmente se necesitan antibióticos fuertes por vía intravenosa.",
  },
  {
    id: 52,
    nombre: "¿Cómo puedo saber si tengo infección ósea?",
    idCategory: "10",
    categoria: "Infección ósea",
    desc: "Los síntomas de una infección en un hueso incluyen: Dolor en el área infectada, escalofríos y fiebre, hinchazón, calor y enrojecimiento del área infectada. En algunas ocasiones, la osteomielitis no causa signos ni síntomas o resulta difícil distinguirlos de otras enfermedades.",
  },
  {
    id: 53,
    nombre: "¿En qué situación se debe alargar una extremidad?",
    idCategory: "6",
    categoria: "Alargamiento óseo",
    desc: "Tener una dismetría de extremidades (Una pierna más larga que la otra) o tener talla muy inferior al promedio son algunos de los principales motivos por lo que se puede elegir un alargamiento de extremidades, tratamiento quirúrgico que se puede realizar en niños y adultos.",
  },
  {
    id: 54,
    nombre: "¿En qué consiste la cirugía de alargamiento?",
    idCategory: "6",
    categoria: "Alargamiento óseo",
    desc: "En casos de disimetrías, el tratamiento inicial es ortopédico. Se indica el uso de una plantilla o talonera del  tamaño acorde a la desigualdad entre las piernas",
  },
  {
    id: 55,
    nombre: "¿Cuáles son las fases del proceso de elongación del hueso?",
    idCategory: "6",
    categoria: "Alargamiento óseo",
    desc: "El proceso de alargamiento óseo consta de 3 fases: Fase de latencia: Periodo entre el día de la cirugía y el comienzo del alargamiento. Fase de alargamiento: El hueso se va estirando progresivamente. Esto lo realiza el propio en su hogar. Fase de consolidación: Periodo que transcurre entre el cese de la fase de alargamiento y el retiro del tutor.",
  },
  {
    id: 56,
    nombre: "¿Cuáles son los riesgos del alargamiento óseo?",
    idCategory: "6",
    categoria: "Alargamiento óseo",
    desc: "Los riesgos ocurren en un 8 a 12% de los casos aproximadamente. Incluyen retardo de consolidación del hueso, no unión, Infección (como cualquier cirugía), contracturas musculares (por lo que debe realizar terapia física  constantemente), consolidación precoz del hueso elongado y fallas del clavo (rotura del material metálico). La falla del clavo ocurre en 1-3%, lo cual es raro.",
  },
  {
    id: 57,
    nombre: "¿Qué es la terapia física?",
    idCategory: "11",
    categoria: "Terapia física",
    desc: "La Terapia Física y Rehabilitación es una especialidad que tiene como objetivo recuperar y mejorar la capacidad funcional y calidad de vida de aquellas personas que sufren de discapacidad por una enfermedad o lesión.",
  },
  {
    id: 58,
    nombre: "¿Qué males trata la terapia física?",
    idCategory: "11",
    categoria: "Terapia física",
    desc: "Este tipo de tratamiento es recomendado para pacientes que fueron sometidos a intervenciones quirúrgicas traumatológicas (fractura, reconstrucciones por traumatismos, roturas ligamentarias) y de ortopedia (Cirugía de artroplastia total de cadera, rodilla y hombro, cirugía descompresiva de columna, correcciones de malformaciones de las extremidades inferiores).",
  },
  {
    id: 59,
    nombre: "¿Qué herramientas se emplean en la fisioterapia?",
    idCategory: "11",
    categoria: "Terapia física",
    desc: "Masoterapia (masaje), movilizaciones, cyriax, electroterapia / electricidad, ondas de choque, crioterapia, potenciación muscular, propiocepción y equilibrio, ejercicios y estiramientos específicos para cada lesión, ergonomía postural y con agujas.",
  },
  {
    id: 60,
    nombre: "¿Cuáles son los objetivos del tratamiento? ",
    idCategory: "11",
    categoria: "Terapia física",
    desc: "Aliviar el dolor, reducir la inflamación, recuperar la movilidad, reentreno a las actividades de la vida diaria y a la actividad deportiva.",
  },
  {
    id: 61,
    nombre: "¿Qué es la neurología?",
    idCategory: "12",
    categoria: "Neurología",
    desc: "La Neurología es la especialidad médica que estudia la estructura, función y desarrollo del sistema nervioso (central, periférico y autónomo) y muscular en estado normal y patológico.",
  },
  {
    id: 62,
    nombre: "¿Qué tipo de enfermedades trata el neurólogo?",
    idCategory: "12",
    categoria: "Neurología",
    desc: "Los neurólogos tienen competencia sobre diversos trastornos médicos, incluyendo enfermedad cerebrovascular, enfermedad de Parkinson, dolores de cabeza, epilepsia, esclerosis múltiple, neuropatías periféricas, infecciones del sistema nervioso, tumores, y complicaciones de los traumatismos craneales y espinales.",
  },
  {
    id: 63,
    nombre: "¿Cuándo acudir a un neurólogo?",
    idCategory: "12",
    categoria: "Neurología",
    desc: "Es recomendable acudir a una consulta de neurología cuando aparecen principalmente estos signos de alarma: Dolor de cabeza intenso o continuado en el tiempo. Dolor irradiado por brazos o piernas. Pérdida de sensibilidad en las extremidades o temblores.",
  },
  {
    id: 64,
    nombre: "¿Por qué con Arthromeds?",
    idCategory: "12",
    categoria: "Neurología",
    desc: "ARTHROMEDS está compuesto no solo por el mejor equipo de traumatología. Sino también por el mejor equipo de Neurología del Hospital Arzobispo Loayza. Nuestro equipo está formado por profesionales expertos en el sector sanitario, lo que garantiza la excelencia en el servicio, la atención personalizada y la seguridad médica.",
  },
  {
    id: 65,
    nombre: "¿Qué es la reumatología?",
    idCategory: "13",
    categoria: "Reumatología",
    desc: "La Reumatología es la especialidad médica que se encarga de prevenir, diagnosticar y tratar las enfermedades musculoesqueléticas  y  autoinmunes sistémicas. Se trata de una de las especialidades que más avances en diagnóstico y tratamiento ha experimentado en los últimos años.",
  },
  {
    id: 66,
    nombre: "¿Qué hace un reumatólogo?",
    idCategory: "13",
    categoria: "Reumatología",
    desc: "Los reumatólogos tratan las enfermedades en las articulaciones de un modo similar al de los traumatólogos, pero no realizan cirugías. Entre las enfermedades comunes que tratan los reumatólogos, se encuentran la artrosis, la gota, la artritis reumatoide, el dolor de espalda crónico, la tendinitis y el lupus.",
  },
  {
    id: 67,
    nombre: "¿Cuándo acudir a un reumatólogo?",
    idCategory: "13",
    categoria: "Reumatología",
    desc: "No has tenido ninguna lesión pero te duelen las articulaciones. Dolor en las articulaciones relacionados con dolor de espalda, psoriasis, fatiga, etc. Dolor de espalda o de las piernas. Otros dolores musculares, de los huesos o las articulaciones.",
  },
  {
    id: 68,
    nombre: "¿Por qué Arthromeds para reumatología?",
    idCategory: "13",
    categoria: "Reumatología",
    desc: "ARTHROMEDS está compuesto no solo por el mejor equipo de traumatología. Sino también por el mejor equipo de reumatología del Hospital Arzobispo Loayza. Nuestro equipo está formado por profesionales expertos en el sector sanitario, lo que garantiza la excelencia en el servicio, la atención personalizada y la seguridad médica.",
  },
  {
    id: 69,
    nombre: "¿Cómo es el procedimiento de la ozonoterapia?",
    idCategory: "14",
    categoria: "Ozonoterapia",
    desc: "Dentro de un ambiente de una sala de procedimientos y mediante el uso tecnológico de una unidad de ozonoterapia, se le inyecta al paciente una cantidad controlada de ozono en la zona articular afectada.",
  },
  {
    id: 70,
    nombre: "¿Cuándo se debe acudir a un especialista?",
    idCategory: "14",
    categoria: "Ozonoterapia",
    desc: "Deberá acudir a la consulta de Ozonoterapia cuando presente un dolor crónico, hernia o protrusión discal, dolor en las articulaciones o patologías degenerativas, y cuando desee mejorar el aporte de oxígeno a los tejidos y mejorar el sistema inmunitario.",
  },
  {
    id: 71,
    nombre: "¿Todos pueden ser sometidos a un tratamiento con ozono?",
    idCategory: "14",
    categoria: "Ozonoterapia",
    desc: "En la evaluación de que si un paciente puede ser sometido a esta terapia con ozono prima la evaluación del factor inflamación. En los casos donde la inflamación o hernia es de tamaño pequeño o moderado, el paciente es candidato ideal para este tipo de tratamiento. Este es el caso de la mayoría de los pacientes. En los casos donde la inflamación o hernia son más severas, el camino más adecuado es la intervención quirúrgica.",
  },
  {
    id: 72,
    nombre: "¿Qué tan eficaz es el tratamiento con ozono (ozonoterapia)?",
    idCategory: "14",
    categoria: "Ozonoterapia",
    desc: "Nuestros resultados son de un 90% de efectividad, no solo en el hecho de disminuir la intensidad del dolor, sino también en conseguir una mejoría de la funcionalidad articular y una reincorporación del paciente a su trabajo y a su vida habitual.",
  },
];
