import React from "react";
import "./styles.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { doctors } from "../../../data/doctors.js";
import { Link } from "react-router-dom";
import "@splidejs/react-splide/css";

export const SliderDocSpecialty = () => {
  return (
    <div id="slider-doc-specialty">
      <div className="slider-doc-title">
        <h2>Especialistas que cuidan de ti</h2>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6 col-lg-4 col-xl-3">
          <Splide
            options={{
              pagination: true,
              arrows: false,
            }}
          >
            {doctors.map(({ id, titulo, especialidad, CMP, img }) => {
              return (
                <SplideSlide key={id}>
                  <div className="card-doc-specialty">
                    <div className="card-doc-img">
                      <img
                        style={{
                          objectFit: "cover",
                          borderRadius: "0px",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                          width: "100%",
                          height: "100%",
                        }}
                        src={img}
                      />
                    </div>
                    <div className="card-doc-text">
                      <h4>{especialidad}</h4>
                      <h3>{titulo}</h3>
                      <p>CMP: {CMP}</p>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-3 doc-text">
          <ul>
            <li>Expertos en el sector sanitario.</li>
            <li>Seguridad médica.</li>
            <li>Especializados en cada rama de la traumatología.</li>
            <li>Ambiente de confianza.</li>
            <li>Recupera tu movilidad sin dolor.</li>
          </ul>
          <Link to={"../nosotros"}>
            <div className="btn-center">
              <button style={{ width: "70%" }} className="main-btn grow">
                Ver equipo
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
