import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./styles.css";

export const CollapseItem = ({ nombre, desc, id }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey={id}>
        <Accordion.Header>{nombre}</Accordion.Header>
        <Accordion.Body>{desc}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
