import react from "react";
import "./styles.css";
import soat from "../../../img/about/SOAT.png";
import team from "../../../img/about/group-chat.png";
import clinics from "../../../img/about/hospital.png";

export const ConventionAbout = () => {
  return (
    <div id="conventions" className="row justify-content-center">
      <div className="conventions-item col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <img src={soat} />
        <h4>SOAT y accidentes personales</h4>
        <p>
          Convenio con SOAT para cubrir no solo el accidente vehicular, sino
          también accidentes personales.
        </p>
      </div>
      <div className="conventions-item col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <img src={team} />
        <h4>Equipo profesional</h4>
        <p>
          Equipo de traumatólogos altamente calificado en cada campo y con
          amplia experiencia en cirugías.
        </p>
      </div>
      <div className="conventions-item col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <img src={clinics} />
        <h4>Instalaciones clínicas</h4>
        <p>
          Gracias a nuestros convenios con clínicas, tenemos acceso a modernas
          instalaciones para mayor comodidad de nuestros pacientes.
        </p>
      </div>
    </div>
  );
};
