import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { services } from "../../data/services";
import { ItemDetail } from "../ItemDetail";
import "./styles.css";

export const ItemDetailContainer = () => {
  const [item, setItem] = useState(null);
  const { itemName } = useParams();

  useEffect(() => {
    setItem(services.find((el) => el.linkName === String(itemName)));
  }, [itemName]);

  return (
    <div>
      <ItemDetail {...item} />
    </div>
  );
};
