import react from "react";
import "./styles.css";
import banner1 from "../../../img/home/doctor.png";
import banner2 from "../../../img/home/banner-inicio2.png";
import banner3 from "../../../img/home/banner-inicio3.png";
import bannerMob1 from "../../../img/home/banner-inicio-mobile.png";
import bannerMob2 from "../../../img/home/banner-inicio-mobile2.png";
import bannerMob3 from "../../../img/home/banner-inicio-mobile3.png";
import { Banner } from "../../../components/Banner";

export const Slider = () => {
  return (
    <div>
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="true"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <a href="https://youtu.be/kBXcjXKr-dY" target={"_blank"}>
              <Banner
                img={banner1}
                imgMob={bannerMob1}
                title={"Clínica Arthromeds"}
                desc={""}
                btn={"Conócenos"}
                display={"block"}
                link={"https://youtu.be/kBXcjXKr-dY"}
              />
            </a>
          </div>
          <div class="carousel-item">
            <Banner
              img={banner2}
              imgMob={bannerMob2}
              title={"Clínica Arthromeds"}
              desc={""}
              btn={"Conócenos"}
              display={"block"}
              link={"https://youtu.be/kBXcjXKr-dY"}
            />
          </div>
          <div class="carousel-item">
            <Banner
              img={banner3}
              imgMob={bannerMob3}
              title={"Clínica Arthromeds"}
              desc={""}
              btn={"Conócenos"}
              display={"block"}
              link={"https://youtu.be/kBXcjXKr-dY"}
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};
