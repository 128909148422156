import react from "react";
import "./styles.css";
import joint from "../../../img/home/joint.png";
import { Link } from "react-router-dom";

export const Target = () => {
  return (
    <div id="target" className="row justify-content-center">
      <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 joint-section">
        <h2 className="target-title">
          Mejor clínica de Traumatología en Lima - Perú
        </h2>
        <img src={joint} alt="joint" />
      </div>
      <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 target-content">
        <p className="target-text">
          Somos una clínica especializada en traumatología en Lima. Contamos con
          un equipo profesional altamente capacitado y especializado en cada
          rama de la traumatología. Además, ofrecemos una atención calidad y con
          altos estándares de seguridad, brindando con ello un ambiente de
          confianza a nuestros pacientes en cuanto a cuidados y adherencia al
          tratamiento. <br /> <br /> En <span>Arthromeds</span> nuestro
          principal objetivo es que vuelvas a recuperar tu movilidad sin dolor.
        </p>
        <Link to={"nosotros"}>
          <button className="main-btn btn-target grow">Ver más</button>
        </Link>
      </div>
    </div>
  );
};
