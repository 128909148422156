export const doctors = [
  {
    id: 1,
    titulo: "Dr. Christian Huamaní",
    nombre: "Huamaní Medina, Christian",
    categorias: "Experto en hombro y rodilla",
    CMP: "45307",
    RNE: "21141",
    especialidad: "Traumatología y Ortopedia",
    experiencia:
      "Creador del sistema fijación externa HMC para alargamientos óseos patentado en INDECOPI. Médico general por la universidad Nacional de San Agustín de Arequipa, con especialidad en ortopedia y traumatología en la Universidad Nacional Mayor de San Marcos y con sub-especialidad de hombro y rodilla en clínica MEDS de medicina Deportiva en Santiago Chile.",
    img: "https://s3-sa-east-1.amazonaws.com/doctoralia.pe/doctor/d61267/d61267e178236460f9d884e394389a30_large.jpg",
    url: "https://www.youtube.com/watch?v=kBXcjXKr-dY",
  },
  {
    id: 2,
    titulo: "Dr. Cristian Meneses",
    nombre: "Huamaní Medina, Christian",
    categorias: "Traumatólogo experto en pie y tobillo",
    CMP: "62661",
    RNE: "33334",
    especialidad: "Traumatología",
    experiencia:
      "Médico Cirujano por la Universidad de Aquino – Bolivia Especialidad de cirugía ortopédica y traumatología por la Universidad de San Martín de Porres. Formación en cirugía de Pie y tobillo en la Clínica Alemana en Santiago de Chile y en la Universidad federal de Sao Paulo – Brazil Formación en Ortopedia Infantil en el Instituto Roosevelt – Bogotá Actualmente me desarrollo como médico asistencias del Instituto de salud del niño de San Borja, de la Clínica Stella Maris Mi desempeño laboral está acordé a las últimas tendencias de tratamiento para garantizar el mejor resultado posible para mis pacientes.",
    img: "https://clinicarthromeds.pe/wp-content/uploads/2020/06/CritiMen-1.jpg",
    url: "",
  },
  {
    id: 3,
    titulo: "Dr. Carlos Mamani",
    nombre: "Mamani, Carlos",
    categorias: "Anestesiólogo experto en manejo del dolor",
    CMP: "62661",
    RNE: "33334",
    especialidad: "Anestesiólogo",
    experiencia:
      "Realizó la especialidad en la universidad Nacional Mayor de San Marcos ostenta el título de Anestesia, Analgesia y Reanimación trabaja en el Instituto Nacional del Niño San Borja, pertenece al grupo de trasplante del Insnsb, trabaja en la clínica Internacional. Experto en anestesia regional, anestesia pediátrica y Terapia del Dolor.",
    img: "https://clinicarthromeds.pe/wp-content/uploads/2020/06/Carlos.jpg",
    url: "",
  },
  {
    id: 4,
    titulo: "Dr. Santiago Acuña",
    nombre: "Acuña, Santiago",
    categorias: "Experto en rodilla, pie y tobillo",
    CMP: "24782",
    RNE: "10651",
    especialidad: "Traumatología y Ortopedia",
    experiencia:
      "Médico cirujano por la Universidad de San Agustín.Especialidad en Ortopedia y Traumatología por la Universidad Federico Villarreal. Mi ejercicio profesional lo he desarrollado en el ámbito docente en las Universidades de San Martín de Porres y en la Universidad Cayetano Heredia.Me desempeño como especialista en el Hospital Militar Central, Hospital Cayetano Heredia y actualmente en el Hospital de Emergencias Grau de EsSalud.",
    img: "https://clinicarthromeds.pe/wp-content/uploads/2020/06/Santia.jpg",
    url: "",
  },
  {
    id: 5,
    titulo: "Dr. Julián Ruiz",
    nombre: "Ruiz, Julián",
    categorias: "Experto en rodilla y traumatología pediátrica",
    CMP: "58444",
    RNE: "29969",
    especialidad: "Traumatología y Ortopedia",
    experiencia:
      "Durante el ejercicio profesional, me he desempeñado como traumatólogo en el Hospital Guillermo Kaelin de la Fuente – EsSalud y en el Instituto de Salud del Niño San Borja, donde actualmente formo parte del staff de Ortopedia infantil teniendo a mi cargo la unidad de artroscopia infantil. Soy miembro activo de la Sociedad Norte Americana de Artroscopia (AANA), la Orthopedic Trauma Association (OTA), la American Academy of Orthopaedic Surgeons (AAOS), La Sociedad Peruana de Ortopedia y Traumatología (SPOT). La calidad del servicio que ofrezco proviene de mi experiencia y del empleo de las técnicas quirúrgicas más avanzadas.",
    img: "https://clinicarthromeds.pe/wp-content/uploads/2020/06/julian.jpg",
    url: "",
  },
  {
    id: 6,
    titulo: "Dr. Hermes Flores",
    nombre: "Flores, Hermes",
    categorias: "Experto en cadera y columna",
    CMP: "58388",
    RNE: "37253",
    especialidad: "Traumatología y Ortopedia",
    experiencia:
      "Médico Cirujano con especialidad en Cirugía Ortopédica y Traumatología por la Universidad Nacional Mayor de San Marcos. Durante el ejercicio de mi profesión, me he desempeñado como traumatólogo en el Hospital Nacional Arzobispo Loayza, mostrando calidad y eficiencia al servicio de mis pacientes; lo cual proviene de mi experiencia y capacitación continua en centros de entrenamiento nacional e internacional.",
    img: "https://clinicarthromeds.pe/wp-content/uploads/2020/06/hermes-2.jpg",
    url: "",
  },
];
