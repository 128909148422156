import { services } from "../data/services";

export function getServices() {
  const servicesList = services;
  return servicesList;
}

export function filterServices(idCategoryService) {
  let filteredServices = getServices().filter(
    (el) => el.idCategoryService === idCategoryService
  );
  return filteredServices;
}
