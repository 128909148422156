import react from "react";
import { CardDoctor } from "../CardDoctor";
import "./styles.css";

export const DoctorList = ({ doctors }) => {
  return (
    <div id="doctor-list" className="row justify-content-center">
      {doctors.map((el) => (
        <CardDoctor key={el.id} {...el} />
      ))}
    </div>
  );
};
