import React from "react";
import { Banner } from "../../../components/Banner";
import { CallToAction } from "../../../components/CallToAction";
import casos from "../../../img/about/banner-casosclinicos.png";
import casosMob from "../../../img/about/banner-casosclinicos-mobile.png";
import teddy from "../../../img/testimonies/testimonio-teddy.png";
import daniel from "../../../img/testimonies/testimonio-danielmarquez.png";
import miqueas from "../../../img/testimonies/testimonio-miqueas.png";
import yamilet from "../../../img/testimonies/testimonio-yamilet.png";
import dimas from "../../../img/testimonies/testimonio-dimas.png";
import javier from "../../../img/testimonies/testimonio-javiergarcia.png";
import luis from "../../../img/testimonies/testimonio-luisangel.png";
import paolo from "../../../img/testimonies/testimonio-paoloballon.png";
import anderson from "../../../img/testimonies/testimonio-anderson.png";
import richard from "../../../img/testimonies/testimonio-richardcalcurian.png";
import heart from "../../../img/icons/casosclinicos-icon-heart.png";
import hope from "../../../img/icons/casosclinicos-icon-hope.png";
import "./styles.css";

export const ClinicCases = () => {
  return (
    <>
      <Banner display={"none"} img={casos} imgMob={casosMob} />
      <div
        id="patients"
        className="row justify-content-around align-items-center"
      >
        <div className="col-md-4 pain-title">
          <h4>
            Conoce a personas que gracias a Arthromeds, recuperaron su
            <span> movilidad sin dolor</span>
          </h4>
        </div>
        <div className="col-md-4 pain-desc">
          <div className="row justify-content-center">
            <div className="col-md-1 icon-patient">
              <span>
                <img src={heart} alt={"corazón"} />
              </span>
            </div>
            <div className="col-md-11">
              <h5>Pacientes satisfechos</h5>
              <p>Estamos llegando a más personas y cambiando más vidas.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-1 icon-patient">
              <span>
                <img src={hope} alt={"esperanza"} />
              </span>
            </div>
            <div className="col-md-11">
              <h5>Devolvemos la esperanza</h5>
              <p>
                Personas que lo perdieron todo, recuperaron la esperanza gracias
                a Arthromeds.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CallToAction
        title={"¡Agenda una cita con nosotros!"}
        text={
          "Comienza tu pronta recuperación de la mano del mejor equipo de traumatología en Lima."
        }
        buttonText={"Agendar una cita"}
        buttonLink={"../contacto"}
      />
      <div id="testimonial-services">
        <div>
          <h2>Personas que cambiaron su vida</h2>
        </div>
        <div className="row justify-content-center align-items-center item-testimonial">
          <div className="col-sm-12 col-md-5">
            <a
              href="https://www.youtube.com/watch?v=uAvfkWQ_d-E"
              target="_blank"
            >
              <img src={yamilet}></img>
            </a>
            <h3>Yamilet Acosta</h3>
            <p>Caso de fractura de tobillo y mano</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=fEzyDP3GAgU"
              target="_blank"
            >
              <img src={daniel}></img>
            </a>
            <h3>Daniel Márquez</h3>
            <p>Alargamiento óseo</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=Uq4R6aXrJT8"
              target="_blank"
            >
              <img src={teddy}></img>
            </a>
            <h3>Teddy Romero</h3>
            <p>Caso de rodilla fracturada</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=4XLUo1rk_kI"
              target="_blank"
            >
              <img src={miqueas}></img>
            </a>
            <h3>Miqueas Eli</h3>
            <p>Caso fractura de mano y muñeca</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=R_fzwsXtzsg"
              target="_blank"
            >
              <img src={dimas}></img>
            </a>
            <h3>Señor Dimas</h3>
            <p>Caso alargamiento óseo</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=We5jwScjmDQ"
              target="_blank"
            >
              <img src={javier}></img>
            </a>
            <h3>Javier García</h3>
            <p>Caso fractura de tibia</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=OUyYW7PVBzM"
              target="_blank"
            >
              <img src={luis}></img>
            </a>
            <h3>Luis Ángel</h3>
            <p>Caso de recuperación por transporte óseo</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=OQkGf3dRz7U"
              target="_blank"
            >
              <img src={paolo}></img>
            </a>
            <h3>Paolo Ballón</h3>
            <p>Caso alargamiento óseo y reconstrucción</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=4XLUo1rk_kI"
              target="_blank"
            >
              <img src={richard}></img>
            </a>
            <h3>Richard Calcurian</h3>
            <p>Caso fractura de cadera</p>
          </div>
          <div className="col-md-5">
            <a
              href="https://www.youtube.com/watch?v=GFYOdsfG3q0"
              target="_blank"
            >
              <img src={anderson}></img>
            </a>
            <h3>Anderson</h3>
            <p>Caso alargamiento óseo</p>
          </div>
        </div>
      </div>
    </>
  );
};
