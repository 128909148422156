import react from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export const CallToAction = ({ title, text, buttonText, buttonLink }) => {
  return (
    <div id="call-to-action">
      <h2>{title}</h2>
      <p>{text}</p>
      <Link to={buttonLink}>
        <button className="second-btn grow">{buttonText}</button>
      </Link>
    </div>
  );
};
