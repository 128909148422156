import React from "react";
import { Helmet } from "react-helmet";
import { Banner } from "../../components/Banner";
import contacto from "../../img/arthromeds-contacto.png";
import contactoMob from "../../img/banner-contacto-mobile.png";
import { Call } from "./Call";
import { FormEmail } from "./FormEmail";
import { Maps } from "./Map";

export const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arthromeds | Contáctanos</title>
      </Helmet>
      <Banner
        title={"Contáctanos"}
        desc={
          "En ArthroMeds Peru ofrecemos una atención con altos estándares de calidad y seguridad, además de entregar un ambiente de confianza a nuestros pacientes en cuanto a los cuidados y adherencia al tratamiento."
        }
        btn={"Contactar"}
        img={contacto}
        imgMob={contactoMob}
        display={"block"}
        link={"https://wa.link/6qf92i"}
      />
      <Call />
      <FormEmail />
      <Maps
        title={"map"}
        source={
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.932604771162!2d-77.04602808536484!3d-12.04815809146635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9cf9e0ba1e1%3A0xb4048560432f6c6f!2sArthromeds%20cl%C3%ADnica%20de%20traumatolog%C3%ADa%20y%20terapia%20f%C3%ADsica!5e0!3m2!1ses-419!2spe!4v1659327320438!5m2!1ses-419!2spe"
        }
        width={"100%"}
        height={"12rem"}
      />
    </>
  );
};
