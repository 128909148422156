import react from "react";
import { CollapseItem } from "../CollapseItem";
import "./styles.css";

export const CollapseList = ({ collapseItems }) => {
  return (
    <div id="item-list" className="collapse-items row justify-content-center">
      {collapseItems.map((el) => (
        <CollapseItem key={el.id} {...el} />
      ))}
    </div>
  );
};
