import react, { useEffect } from "react";
import "./styles.css";
import PureCounter from "@srexi/purecounterjs";
import { CounterItem } from "../CounterItem";

export const CounterSection = () => {
  return (
    <div id="counter-section" className="row justify-content-center">
      <CounterItem
        title={"Alargamientos"}
        counterStart={"0"}
        counterEnd={"2456"}
        counterDuration={"1"}
      />
      <CounterItem
        title={"Artroscopías"}
        counterStart={"0"}
        counterEnd={"5582"}
        counterDuration={"1"}
      />
      <CounterItem
        title={"Prótesis"}
        counterStart={"0"}
        counterEnd={"1575"}
        counterDuration={"1"}
      />
    </div>
  );
};
