import react from "react";
import doctor from "../../../img/about/doctor.png";
import call from "../../../img/about/call.png";
import trust from "../../../img/about/trust.png";
import "./styles.css";

export const TrustServices = () => {
  return (
    <div id="trust-about">
      <div className="title-trust">
        <h2>¿Por qué confiar en nosotros?</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 col-lg-4 item-trust">
          <img src={doctor} alt="equipo médico" />
          <h4>Un equipo médico de calidad</h4>
          <p>
            Contamos con una cartera propia de cirujanos nacionales escogidos
            por su experiencia (más de 10 años de media en el ejercicio médico),
            profesionalidad y reputación a nivel estatal. Que operan en clínicas
            privadas de primera línea.
          </p>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 item-trust">
          <img src={trust} alt="confianza" />
          <h4>Seguridad y confianza</h4>
          <p>
            Hemos logrado atender con éxito los casos clínicos más complejos.
            Nuestra amplia experiencia atendiendo dichos casos nos proporcionan
            la seguridad que necesitan nuestros pacientes de que recuperarse ¡sí
            es posible!
          </p>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 item-trust">
          <img src={call} alt="atención personalizada" />
          <h4>Atención inmediata y personalizada</h4>
          <p>
            Proporcionamos una atención personalizada, inmediata y de calidad
            gracias a la labor de nuestros especialistas del departamento de
            atención al paciente, quienes tramitarán todas las gestiones
            administrativas y solucionarán todas tus dudas para que tan solo te
            preocupes de tu salud.
          </p>
        </div>
      </div>
    </div>
  );
};
