import React, { useState, useEffect } from "react";
import { SpecialtyList } from "../SpecialtyList";
import { getSpecialties, filterSpecialties } from "../../services/specialties";
import "./styles.css";
import { specialties } from "../../data/specialties";

export const SpecialtyListContainer = ({
  titleStart,
  titleEnd,
  btnOne,
  btnTwo,
  btnThree,
}) => {
  const [filteredSpecialty, setFilteredSpecialty] = useState(null);
  const [isActiveOne, setIsActiveOne] = useState(true);
  const [isActiveTwo, setIsActiveTwo] = useState(false);
  const [isActiveThree, setIsActiveThree] = useState(false);

  useEffect(() => {
    setFilteredSpecialty(getSpecialties());
  }, []);

  function handleSpecialty(e) {
    let catSpecialty = e.target.value;
    if (catSpecialty === "1") {
      setFilteredSpecialty(getSpecialties());
      handleActiveOne();
    } else if (catSpecialty === "2") {
      setFilteredSpecialty(filterSpecialties(catSpecialty));
      handleActiveTwo();
    } else if (catSpecialty === "3") {
      setFilteredSpecialty(filterSpecialties(catSpecialty));
      handleActiveThree();
    } else {
      setFilteredSpecialty(getSpecialties());
      handleActiveOne();
    }
  }

  function handleActiveOne() {
    setIsActiveOne(true);
    setIsActiveTwo(false);
    setIsActiveThree(false);
  }

  function handleActiveTwo() {
    setIsActiveOne(false);
    setIsActiveTwo(true);
    setIsActiveThree(false);
  }

  function handleActiveThree() {
    setIsActiveOne(false);
    setIsActiveTwo(false);
    setIsActiveThree(true);
  }

  return (
    <div id="item-list-container">
      <h2>
        {titleStart}
        <span> {titleEnd}</span>
      </h2>
      <div className="item-filter row justify-content-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-1">
          <p>Filtrar por: </p>
        </div>
        <div className="col-md-6">
          <button
            value={"1"}
            className={isActiveOne ? "btn-active" : "btn-inactive"}
            onClick={handleSpecialty}
          >
            {btnOne}
          </button>
          <button
            value={"2"}
            className={isActiveTwo ? "btn-active" : "btn-inactive"}
            onClick={handleSpecialty}
          >
            {btnTwo}
          </button>
          <button
            value={"3"}
            className={isActiveThree ? "btn-active" : "btn-inactive"}
            onClick={handleSpecialty}
          >
            {btnThree}
          </button>
        </div>
      </div>
      <SpecialtyList
        items={filteredSpecialty !== null ? filteredSpecialty : specialties}
      />
    </div>
  );
};
