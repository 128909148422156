import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { specialties } from "../../data/specialties";
import { ItemDetail } from "../ItemDetail";
import "./styles.css";

export const SpecialtyDetailContainer = () => {
  const [item, setItem] = useState(null);
  const { itemName } = useParams();

  useEffect(() => {
    setItem(specialties.find((el) => el.linkName === String(itemName)));
  }, [itemName]);

  return (
    <div>
      <ItemDetail {...item} />
    </div>
  );
};
