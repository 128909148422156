import React from "react";
import "./styles.css";
import { Banner } from "../../../components/Banner";
import { SoatAbout } from "../SoatAbout";
import convenios from "../../../img/about/banner-convenios-clinicos.jpg";
import conveniosMob from "../../../img/about/banner-conveniosclinicos-mobile.png";
import prestaciones from "../../../img/about/prestaciones-ambulatorias.jpg";

export const ClinicConvention = () => {
  return (
    <>
      <Banner display={"none"} img={convenios} imgMob={conveniosMob} />
      <div id="clinic-convention">
        <div className="text-center convention-title">
          <h2>Prestaciones Ambulatorias</h2>
        </div>
        <div className="row justify-content-center align-items-center clinic-convention-item">
          <div className="col-md-4">
            <img src={prestaciones} />
          </div>
          <div className="col-md-4">
            <p>
              ArthroMeds Perú posee convenio con las más importantes clínicas y
              compañías de seguro, lo que permite optimizar su plan de salud con
              valores preferenciales en atenciones ambulatorias y hospitalarias.
            </p>
            <ul>
              <li>Consultas Médicas en traumatología en rehabilitación</li>
              <li>
                Consultas médicas en neurología, reumatología, oftalmología y
                cardiología
              </li>
              <li>Exámenes de rayos X</li>
              <li>Exámenes de Laboratorio</li>
              <li>Kinesiología y acupuntura</li>
              <li>Ozonoterapia</li>
            </ul>
          </div>
        </div>
      </div>
      <SoatAbout display={"block"} />
    </>
  );
};
