import react from "react";
import "./styles.css";
import stethoscope from "../../../img/home/stethoscope.png";
import calendar from "../../../img/home/calendar.png";
import radar from "../../../img/home/radar.png";
import { Link } from "react-router-dom";

export const Tasks = () => {
  return (
    <div id="tasks">
      <h2 className="title">
        ¿Qué deseas <span>realizar hoy?</span>
      </h2>
      <div className="row justify-content-around">
        <div className="col-md-3 col-lg-3 col-xl-2 box grow">
          <Link to={"contacto"}>
            <img src={calendar} />
            <h4>Agendar una cita</h4>
          </Link>
        </div>
        <div className="col-md-3 col-lg-3 col-xl-2 box grow">
          <Link to={"servicios"}>
            <img src={radar} />
            <h4>Ver nuestros servicios</h4>
          </Link>
        </div>
        <div className="col-md-3 col-lg-3 col-xl-2 box grow">
          <Link to={"especialidades"}>
            <img src={stethoscope} />
            <h4>Ver especialidades</h4>
          </Link>
        </div>
      </div>
    </div>
  );
};
