import react from "react";
import "./styles.css";
import hmc from "../../../img/about/hmc.jpg";

export const PatentedAbout = () => {
  return (
    <div id="patented-about">
      <div>
        <h2>H.M.C. Tecnología patentada</h2>
      </div>
      <div className="row justify-content-center align-items-center patented-section">
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <img className="grow" src={hmc} alt="especialistas-hmc" />
        </div>
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <p>
            El sistema H.M.C, es el nuevo modelo de fijador externo, diseñado en
            el Servicio de Traumatología del Hospital Arzobispo Loayza que
            permite reconstruir lesiones con pérdida ósea masiva de tibia y
            fémur, mediante transportación ósea. <br /> <br /> Gracias a los
            avances en cuanto a técnicas y equipo quirúrgico, en la actualidad
            es posible corregir deformidades alargar, acortar, enderezar,
            reconstruir el hueso afectado por lesiones o malformaciones
            congénitas. <br /> <br /> Esta tecnología fue diseñada por el Dr.
            Christian Huamani de la clínica Arthromeds. Dando la esperanza a
            nuestros pacientes de volver a su completa normalidad de movimiento
            en mediano plazo.
          </p>
        </div>
      </div>
    </div>
  );
};
