import "./App.css";
import { Footer } from "./components/shared/Footer";
import { Navbar } from "./components/shared/Navbar";
import { Home } from "./pages/Home";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { About } from "./pages/About";
import { Services } from "./pages/Services";
import { Specialties } from "./pages/Specialties";
import { TeamAbout } from "./pages/Team";
import { Contact } from "./pages/Contact";
import { ItemDetailContainer } from "./components/ItemDetailContainer";
import { SpecialtyDetailContainer } from "./components/SpecialtyDetailContainer";
import { ClinicConvention } from "./pages/About/ClinicConvention";
import { WhatsApp } from "./components/WhatsApp";
import { useLayoutEffect } from "react";
import { ClinicCases } from "./pages/About/ClinicCases";
import { ProvinceService } from "./pages/Services/ProvinceService";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <>
      <WhatsApp />
      <Navbar />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="nosotros" element={<About />} />
          <Route path="servicios" element={<Services />} />
          <Route
            path="/servicios/:itemName"
            element={<ItemDetailContainer />}
          />
          <Route path="servicios-provincia" element={<ProvinceService />} />
          <Route path="especialidades" element={<Specialties />} />
          <Route
            path="/especialidades/:itemName"
            element={<SpecialtyDetailContainer />}
          />
          <Route path="nosotros/equipo" element={<TeamAbout />} />
          <Route
            path="nosotros/convenios-clinicos"
            element={<ClinicConvention />}
          />
          <Route path="nosotros/casos-clinicos" element={<ClinicCases />} />
          <Route path="contacto" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Wrapper>
      <Footer />
    </>
  );
}

export default App;
