import react from "react";
import { Helmet } from "react-helmet";
import { SliderServices } from "./SliderServices";
import { ItemListContainer } from "../../components/ItemListContainer";
import { CounterSection } from "../../components/CounterSection";
import { TrustServices } from "./TrustServices";
import { ProgramServices } from "./ProgramServices";
import { CollapseListContainer } from "../../components/CollapseListContainer";
import { TestimonialServices } from "./TestimonialServices";

export const Services = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arthromeds | Servicios médicos</title>
      </Helmet>
      <SliderServices />
      <ItemListContainer
        titleStart={"Conoce nuestros"}
        titleEnd={"servicios"}
        btnOne={"Ver todos"}
        btnTwo={"Servicios clínicos"}
        btnThree={"Cirugía especializada"}
      />
      <CounterSection />
      <TrustServices />
      <TestimonialServices />
      <ProgramServices />
      <CollapseListContainer idCategory={"1"} />
    </>
  );
};
