import React, { useRef, useState } from "react";
import "./styles.css";
import emailjs from "@emailjs/browser";
import Toast from "react-bootstrap/Toast";

export const FormEmail = () => {
  const form = useRef();
  const [show, setShow] = useState(false);
  const [background, setBackground] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_nyjjjxf",
        "template_i4wxd67",
        form.current,
        "9aIXlbsJVtJWEnJAS"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShow(true);
          changeBackground();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const changeBackground = () => {
    return <div className="background-darken"></div>;
  };

  return (
    <div id="email-form">
      <div className="email-title">
        <h2>Reserva tu cita</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5 col-xl-4 message-form">
          <form ref={form} onSubmit={sendEmail}>
            <input placeholder="Nombre" name="from_name" />
            <input placeholder="Teléfono" name="phone" />
            <textarea rows={"6"} placeholder="Mensaje" name="message" />
            <button
              style={{ width: "100%" }}
              type="submit"
              className="main-btn grow"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
      <Toast
        className="toast"
        onClose={() => setShow(false)}
        show={show}
        delay={4000}
        autohide
      >
        <Toast.Body>¡Tu correo se ha enviado correctamente!👨‍⚕️</Toast.Body>
      </Toast>
    </div>
  );
};
