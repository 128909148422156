import react from "react";
import "./styles.css";
import atencion from "../../../img/atencion.jpg";
import { Link } from "react-router-dom";

export const ProgramServices = () => {
  return (
    <div id="program-services">
      <div>
        <h2>Programa para pacientes de provincia</h2>
      </div>
      <div className="row justify-content-center align-items-center program-section">
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <img className="grow" src={atencion} alt="especialistas-hmc" />
        </div>
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <p>
            En la clínica ARTHROMEDS contamos con la logística necesaria para
            poder atender pacientes de provincia que requieran de una
            intervención quirúrgica. Contamos con la estructura y el staff
            preparado para brindarte la mejor atención e inmediatez que
            requieras. <br /> <br /> Si te encuentras en provincias y estás
            buscando una 2da opinión clínica traumatológica especializada,
            confía en nosotros. En Lima, somos la principal clínica de
            traumatología.
          </p>
          <Link to={"../servicios-provincia"}>
            <button className="main-btn grow btn-program">Ver más</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
