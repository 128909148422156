import react from "react";
import "./styles.css";
import regenerativa from "../../../img/about/regenerativa.jpg";

export const MedicineAbout = () => {
  return (
    <div id="medicine-about">
      <div>
        <h2>Medicina regenerativa</h2>
      </div>
      <div className="row justify-content-center align-items-center medicine-section">
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <p>
            Se llama Medicina Regenerativa al conjunto de tratamientos, técnicas
            o terapias que emplean material biológico (células y tejidos) de la
            propia persona para aliviar, mejorar o curar ciertas enfermedades.
            <br /> <br /> Se trata de emplear los propios mecanismos que emplean
            las células de nuestro cuerpo en aquellas zonas que puedan estar más
            dañadas, reparando o regenerando esos tejidos y rejuveneciéndolos
            para recuperar su funcionalidad.
          </p>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <img
            className="grow"
            src={regenerativa}
            alt="especialistas-regenerativo"
          />
        </div>
      </div>
    </div>
  );
};
