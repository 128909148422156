import React, { useState, useEffect } from "react";
import { questions } from "../../data/questions";
import { getFilteredQuestions } from "../../services/questions";
import { CollapseList } from "../CollapseList";
import "./styles.css";

export const CollapseListContainer = ({ idCategory }) => {
  const [filteredQuestions, setFilteredQuestions] = useState(null);

  useEffect(() => {
    setFilteredQuestions(getFilteredQuestions(idCategory));
  }, [idCategory]);

  return (
    <div id="questions">
      <div>
        <h2>Preguntas frecuentes</h2>
      </div>
      <div className="">
        <CollapseList
          collapseItems={
            filteredQuestions !== null ? filteredQuestions : questions
          }
        />
      </div>
    </div>
  );
};
