import react from "react";
import "./styles.css";
import team1 from "../../../img/clinica-especialista.jpg";
import team2 from "../../../img/about/banner-nosotros2.png";
import team3 from "../../../img/about/banner-nosotros3.png";
import Carousel from "react-bootstrap/Carousel";

export const CareAbout = () => {
  return (
    <div id="care-about">
      <div className="text-center mw-5">
        <h2>El equipo de traumatólogos que cuida de todos</h2>
      </div>
      <div className="carousel-doctors">
        <Carousel>
          <Carousel.Item style={{ height: "36rem" }}>
            <img className="d-block w-100" src={team1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item style={{ height: "36rem" }}>
            <img className="d-block w-100" src={team2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item style={{ height: "36rem" }}>
            <img className="d-block w-100" src={team3} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
      </div>
      <p className="care-text">
        ARTHROMEDS está compuesto no solo por el mejor equipo de traumatología
        en Lima. Nuestro equipo está formado por profesionales expertos en el
        sector sanitario, lo que garantiza la excelencia en el servicio, la
        atención personalizada y la seguridad médica. Dicha excelencia viene
        avalada por todos los pacientes que hemos atendido, la exhaustiva
        selección de nuestro cuadro médico y la calidad y comodidad de nuestras
        clínicas asociadas.
      </p>
    </div>
  );
};
