export const specialties = [
  {
    id: 1,
    nombre: "Terapia física",
    linkName: "terapia-fisica",
    titulo: "Servicio de Terapia Física y Rehabilitación en Lima - Perú",
    idCategory: "11",
    idCategorySpecialty: "3",
    categoria: "Servicios clínicos",
    desc1:
      "La terapia física y de rehabilitación es muy importante para tratar lesiones del aparato locomotor, ya sea desde un enguince simple de tobillo hasta grandes lesiones de cadera o rodilla. ",
    desc2:
      "Este tipo de tratamiento es recomendado para pacientes que fueron sometidos a intervenciones quirúrgicas traumatológicas (fractura, reconstrucciones por traumatismos, roturas ligamentarias) y de ortopedia (Cirugía de artoplastía total de cadera, rodilla y hombro, cirugía descompresiva de columna, correcciones de malformaciones de las extremidades inferiores).",
    img: "https://i.ibb.co/nQcmX5y/physical-therapy.png",
    img2: "https://i.ibb.co/9sr8zkN/terapiafisica1.jpg",
    img3: "https://i.ibb.co/SR14P2L/terapiafisica2.jpg",
    imgBanner: "https://i.ibb.co/sFbnKd5/banner-terapiafisica.png",
    imgBannerMob: "https://i.ibb.co/NVtxcJj/banner-terapiafisica-mobile.png",
    imgVideo: "https://i.ibb.co/GCqyvcG/testimonio-luisangel.png",
    nameTestimony: "Luis Ángel",
    descTestimony: "Caso de recuperación por transporte óseo",
    urlVideo: "https://www.youtube.com/watch?v=OUyYW7PVBzM",
  },
  {
    id: 2,
    nombre: "Neurología",
    linkName: "neurologia",
    titulo: "Neurología en Lima - Perú",
    idCategory: "12",
    idCategorySpecialty: "3",
    categoria: "Servicios clínicos",
    desc1:
      "Un neurólogo es un médico que se especializa en el diagnóstico y el tratamiento de las enfermedades del cerebro y la médula espinal. En la clínica ARTHROMEDS contamos el mejor equipo de neurología, los cuales cuentan con herramientas modernas como Eletromiografías, procedimiento de diagnóstico que se utiliza para evaluar la salud de los músculos y las células nerviosas que los controlan (neuronas motoras).",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en neurología para ello.",
    img: "https://i.ibb.co/wccSqf6/brain.png",
    img2: "https://i.ibb.co/7gGDWPk/neurologia1.png",
    img3: "https://i.ibb.co/2k5MhmX/neurologia2.jpg",
    imgBanner: "https://i.ibb.co/251CYzp/banner-neurologia.jpg",
    imgBannerMob: "https://i.ibb.co/c89sxpb/banner-neurologia-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 3,
    nombre: "Reumatología",
    linkName: "reumatologia",
    titulo: "Reumatología en Lima - Perú",
    idCategory: "13",
    idCategorySpecialty: "3",
    categoria: "Servicios clínicos",
    desc1:
      "La reumatología es una disciplina de la Medicina Interna que se especializa en el diagnóstico y tratamiento de enfermedades que afectan a las articulaciones, los músculos, tendones y huesos. Estas enfermedades pueden ser diversas y, generalmente, se expresan por dolor persistente e inflamación.",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en reumatología para ello.",
    img: "https://i.ibb.co/rt57pDN/physiotherapist.png",
    img2: "https://i.ibb.co/pPVc30P/reumatologia1.jpg",
    img3: "https://i.ibb.co/2vrnvY8/reumatologia2.jpg",
    imgBanner: "https://i.ibb.co/nmdMpn3/banner-reumatologia.jpg",
    imgBannerMob: "https://i.ibb.co/Ry8dh2d/banner-reumatologia-mobile.png",
    imgVideo: "https://i.ibb.co/R73jS8F/testimonio-anderson.png",
    nameTestimony: "Anderson",
    descTestimony: "Caso de alargamiento óseo",
    urlVideo: "https://www.youtube.com/watch?v=GFYOdsfG3q0",
  },
  {
    id: 4,
    nombre: "Ozonoterapia",
    linkName: "ozonoterapia",
    titulo: "Ozonoterapia en Lima - Perú",
    idCategory: "14",
    idCategorySpecialty: "3",
    categoria: "Servicios clínicos",
    desc1:
      "El ozono es un gas medicinal que se lleva utilizando durante muchos años en el tratamiento de distintas patologías articulares, como en el caso de artrosis de rodilla, cadera, hombro, patologías tendinosas y en patologías discales.",
    desc2:
      "Deberá acudir a la consulta de Ozonoterapia cuando presente un dolor crónico, hernia o protrusión discal, dolor en las articulaciones o patologías degenerativas, y cuando desee mejorar el aporte de oxígeno a los tejidos y mejorar el sistema inmunitario.",
    img: "https://i.ibb.co/S3JygKP/human-body.png",
    img2: "https://i.ibb.co/3N9zydz/ozonoterapia1.jpg",
    img3: "https://i.ibb.co/R4LMBS9/ozonoterapia2.jpg",
    imgBanner: "https://i.ibb.co/JnQxnJn/banner-ozonoterapia.png",
    imgBannerMob: "https://i.ibb.co/Dtz0P5f/banner-ozonoterapia-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 5,
    nombre: "Rodilla",
    linkName: "rodilla",
    titulo: "Traumatólogos especialistas en rodilla en Lima - Perú",
    idCategory: "15",
    idCategorySpecialty: "2",
    categoria: "Especialidades",
    desc1:
      "Si tienes un dolor en la rodilla (o tienes la rodilla hinchada) puede deberse a varias causas. Puede tratarse de una  inflamación debido a un golpe que recibiste, un problema con los ligamentos y/o meniscos, presencia de artrosis e incluso a una posible rodilla fracturada. ",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en rodilla para ello.",
    img: "https://i.ibb.co/6HJRHJv/rodilla.png",
    img2: "https://i.ibb.co/hKtd2LQ/rodilla-img.jpg",
    img3: "https://i.ibb.co/w7YSx0W/pacientesprovincia4.jpg",
    imgBanner: "https://i.ibb.co/SRWx5Sr/rodilla-banner.jpg",
    imgBannerMob: "https://i.ibb.co/y6z177G/banner-rodilla-mobile.png",
    imgVideo: "https://i.ibb.co/CJz194y/testimonio-teddy.png",
    nameTestimony: "Teddy Romero",
    descTestimony: "Caso de rodilla fracturada",
    urlVideo: "https://www.youtube.com/watch?v=Uq4R6aXrJT8",
  },
  {
    id: 6,
    nombre: "Pie y tobillo",
    linkName: "pie-y-tobillo",
    titulo: "Traumatólogos especialistas en pie y tobillo en Lima - Perú",
    idCategory: "16",
    idCategorySpecialty: "2",
    categoria: "Especialidades",
    desc1:
      "Si tienes dolor en el pie, puede originarse en distintas zonas. Puede tratarse de un dolor en la planta de pie izquierdo o el pie derecho, un dolor de pie originado en el talón, presencia de juanetes, incluso sensación de dolor sin presencia de inflamación.",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en pie y tobillo para ello.",
    img: "https://i.ibb.co/nDw1KLF/tobillo.png",
    img2: "https://i.ibb.co/FYhVxxh/mejor-traumatologo-especialista-en-tobillo-y-pie-01.jpg",
    img3: "https://i.ibb.co/bH9xvrb/traumatologo-especialista-en-pie-y-tobillo-en-lima-02.jpg",
    imgBanner: "https://i.ibb.co/k3VTMfb/banner-pieytobillo.jpg",
    imgBannerMob: "https://i.ibb.co/9qh4kL5/banner-pieytobillo-mobile.png",
    imgVideo: "https://i.ibb.co/28hxBMd/testimonio-yamilet.png",
    nameTestimony: "Yamilet Acosta",
    descTestimony: "Caso de fractura de tobillo y mano",
    urlVideo: "https://www.youtube.com/watch?v=uAvfkWQ_d-E&t",
  },
  {
    id: 7,
    nombre: "Columna",
    linkName: "columna",
    titulo: "Traumatólogos especialistas en columna en Lima - Perú",
    idCategory: "17",
    idCategorySpecialty: "2",
    categoria: "Especialidades",
    desc1:
      "Tuviste un golpe o un accidente por la zona de la columna que causa dolor? Un golpe o mal movimiento puede generar dolor en la columna baja, la zona dorsal, dolor lumbar. Lo que puede generar estas molestias puede ser desde un mal estiramiento hasta una posible fractura de columna vertebral. ",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en columna para ello.",
    img: "https://i.ibb.co/ccyLL1D/columna.png",
    img2: "https://i.ibb.co/Kj3z4QJ/columna1.jpg",
    img3: "https://i.ibb.co/vPPYJs5/columna2.jpg",
    imgBanner: "https://i.ibb.co/Bn1wRXn/banner-columna.jpg",
    imgBannerMob: "https://i.ibb.co/dkXSTZw/banner-columna-mobile.png",
    imgVideo: "https://i.ibb.co/MMkp7W5/testimonio-dimas.png",
    nameTestimony: "Sr. Dimas",
    descTestimony: "Caso de alargamiento óseo",
    urlVideo: "https://www.youtube.com/watch?v=R_fzwsXtzsg",
  },
  {
    id: 8,
    nombre: "Mano y muñeca",
    linkName: "mano-y-muneca",
    titulo: "Traumatólogos especialistas en mano y muñeca en Lima - Perú",
    idCategory: "18",
    idCategorySpecialty: "2",
    categoria: "Especialidades",
    desc1:
      "Tienes actualmente un dolor en la mano o muñeca o quizás sufriste un golpe a accidente que ha provocado dolor en el movimiento de la extremidad. Esto puede deberse a diversos factores, desde una lesión en los tendones, lesión dedo en resorte, tunel del carpo, mano traumática; hasta una posible fractura en la muñeca o en la mano, fractura o lesión en los dedos, trapecio, escafoides, metatarsiano, metacarpiano, piramidal y entre otro tipo de lesiones. ",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en mano y muñeca para ello.",
    img: "https://i.ibb.co/RgxPX2j/muneca.png",
    img2: "https://i.ibb.co/cNzcKKC/mu-ecaymano1.jpg",
    img3: "https://i.ibb.co/C6dVZzF/mu-ecaymano2.jpg",
    imgBanner: "https://i.ibb.co/3CLdLvQ/banner-mano.png",
    imgBannerMob: "https://i.ibb.co/0cZq2s5/banner-manoymuneca-mobile.png",
    imgVideo: "https://i.ibb.co/xSs6Z1T/testimonio-miqueas.png",
    nameTestimony: "Miqueas Eli",
    descTestimony: "Caso fractura de mano y muñeca",
    urlVideo: "https://www.youtube.com/watch?v=4XLUo1rk_kI&t",
  },
  {
    id: 9,
    nombre: "Cadera",
    linkName: "cadera",
    titulo: "Traumatólogos especialistas en cadera en Lima - Perú",
    idCategory: "19",
    idCategorySpecialty: "2",
    categoria: "Especialidades",
    desc1:
      "Probablemente has sentido dolor de pelvis o por la zona de la cadera izquierda o derecha. O dolores de cadera que se acompañan por presencia de dolor en otras zonas como la zona lumbar, sensación de dolor de cadera que baja por la pierna, dolor de cadera al andar e incluso solo estando sentado.",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en cadera para ello.",
    img: "https://i.ibb.co/M6VNKVG/pelvis.png",
    img2: "https://i.ibb.co/5hnckPd/cadera1.jpg",
    img3: "https://i.ibb.co/Pt11F6t/cadera2.jpg",
    imgBanner: "https://i.ibb.co/C8JXdrk/banner-cadera.png",
    imgBannerMob: "https://i.ibb.co/w6XLLSp/banner-cadera-mobile.png",
    imgVideo: "https://i.ibb.co/NCHKhJm/testimonio-richardcalcurian.png",
    nameTestimony: "Richard Calcurian",
    descTestimony: "Caso fractura de cadera",
    urlVideo: "https://www.youtube.com/watch?v=uw4bZ-oQg5w",
  },
  {
    id: 10,
    nombre: "Hombro",
    linkName: "hombro",
    titulo: "Traumatólogos especialistas en hombro en Lima - Perú",
    idCategory: "20",
    idCategorySpecialty: "2",
    categoria: "Especialidades",
    desc1:
      "Tienes la sensación de dolor en el hombro derecho o izquierdo? Estos dolores pueden estar acompañados con dolores en otras zonas, como dolor en el hombro y en el cuello, dolor de hombro junto al brazo. La presencia de dolor puede darse por situaciones como al levantar los brazos, al hacer flexiones, luego de ejercicios excesivos en el gym o luego de un accidente o golpe.",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en hombro para ello.",
    img: "https://i.ibb.co/h9kP6VH/hombro.png",
    img2: "https://i.ibb.co/BTFMJXG/hombro1.jpg",
    img3: "https://i.ibb.co/BjVcnR5/hombro2.jpg",
    imgBanner: "https://i.ibb.co/dtn97T1/banner-hombro.jpg",
    imgBannerMob: "https://i.ibb.co/VxbYTQp/banner-hombro-mobile.png",
    imgVideo: "https://i.ibb.co/5MQBGgM/testimonio-paoloballon.png",
    nameTestimony: "Paolo Ballón",
    descTestimony: "Caso de fractura expuesta con osteomielitis",
    urlVideo: "https://www.youtube.com/watch?v=qEVHxwyKVwc",
  },
];
