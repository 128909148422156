import React from "react";
import "./styles.css";

export const Banner = ({ title, desc, btn, img, display, link, imgMob }) => {
  return (
    <>
      <div
        className="banner row justify-content-start align-items-center"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: `${display}` }} className="col-md-4 banner-text">
          <h2>{title}</h2>
          <p>{desc}</p>
          <a href={link} target={"_blank"}>
            <button className="main-btn grow btn-banner-mob">{btn}</button>
          </a>
        </div>
      </div>
      <div
        className="banner-mob row justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${imgMob})`,
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: `${display}` }} className="col-md-4 banner-text">
          <h2>{title}</h2>
          <p>{desc}</p>
          <a href={link} target={"_blank"}>
            <button className="main-btn grow btn-banner-mob">{btn}</button>
          </a>
        </div>
      </div>
    </>
  );
};
