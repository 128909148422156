import react, { useState } from "react";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import "./styles.css";

export const CardDoctor = ({
  id,
  nombre,
  titulo,
  categorias,
  CMP,
  RNE,
  experiencia,
  especialidad,
  img,
  url,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="card-doctor col-md-3 col-lg-3 col-xl-3">
      <div>
        <img src={img} alt={titulo} />
      </div>
      <div>
        <h5>{titulo}</h5>
        <p>{categorias}</p>
        <button className="second-btn grow" onClick={() => setModalShow(true)}>
          Ver perfil
        </button>
      </div>
      <ModalDoctor
        nombre={nombre}
        categorias={categorias}
        CMP={CMP}
        RNE={RNE}
        especialidad={especialidad}
        experiencia={experiencia}
        img={img}
        url={url}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export const ModalDoctor = (props) => {
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-4 modal-img">
            <img src={props.img} />
          </div>
          <div className="col-md-7 modal-desc">
            <h4>{props.nombre}</h4>
            <p>{props.categorias}</p>
            <p className="d-inline-block">CMP: {props.CMP} </p>
            <p className="d-inline-block cod-doc">RNE: {props.RNE}</p>
            <h4>Especialidad</h4>
            <p>{props.especialidad}</p>
            <h4>Experiencia</h4>
            <p>{props.experiencia}</p>
            <a href={props.url} target={"_blank"}>
              <h5>Conoce más</h5>
            </a>
            <div className="icons row justify-content-start">
              <div className="col-md-1 social-modal">
                <FaFacebook size={20} color="#9fa5b2" />
              </div>
              <div className="col-md-1 social-modal">
                <FaInstagram size={20} color="#9fa5b2" />
              </div>
              <div className="col-md-1 social-modal">
                <FaYoutube size={20} color="#9fa5b2" />
              </div>
              <div className="col-md-1 social-modal">
                <FaTiktok size={20} color="#9fa5b2" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
