import React from "react";
import { Banner } from "../../../components/Banner";
import { CallToAction } from "../../../components/CallToAction";
import casos from "../../../img/about/banner-casosclinicos.png";
import doctor from "../../../img/about/doctor.png";
import trust from "../../../img/about/trust.png";
import banner from "../../../img/services/banner-provincia.png";
import provincia1 from "../../../img/services/pacientesprovincia1.jpg";
import provincia3 from "../../../img/services/pacientesprovincia3.jpg";
import provincia4 from "../../../img/services/pacientesprovincia4.jpg";
import convenios from "../../../img/about/banner-convenios-clinicos.jpg";
import prestaciones from "../../../img/about/prestaciones-ambulatorias.jpg";
import "./styles.css";

export const ProvinceService = () => {
  return (
    <>
      <Banner display={"none"} img={banner} />
      <div
        id="patients"
        className="row justify-content-around align-items-center"
      >
        <div className="col-md-4 pain-title">
          <h4>
            Programa para pacientes
            <span> de provincia</span>
          </h4>
        </div>
        <div className="col-md-4 pain-desc">
          <div className="row justify-content-center">
            <div className="col-md-1">
              <span>
                <img src={doctor} alt={"doctor"} />
              </span>
            </div>
            <div className="col-md-11">
              <h5>Staff especializado</h5>
              <p>
                Contamos con la estructura y el staff preparado para brindarte
                la mejor atención e inmediatez que requieras.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-1">
              <span>
                <img src={trust} alt={"confianza"} />
              </span>
            </div>
            <div className="col-md-11">
              <h5>Logística necesaria</h5>
              <p>
                Contamos con la logística necesaria para poder atender pacientes
                de provincia que requieran de una intervención quirúrgica.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CallToAction
        title={"¡Agenda una cita con nosotros!"}
        text={
          "Comienza tu pronta recuperación de la mano del mejor equipo de traumatología en Lima."
        }
        buttonText={"Agendar una cita"}
        buttonLink={"../contacto"}
      />
      <div id="province">
        <div className="province-title">
          <h2>Programa para pacientes de provincia</h2>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 province-img">
            <img src={provincia1} />
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 province-step">
            <h3>Reunión por telemedicina</h3>
            <p>
              Para aquellos pacientes que se encuentren distantes y que
              requieran de una 2da opinión clínica especializada, ofrecemos el
              servicio clínico de telemedicina en la cual, por medio de servicio
              de video llamada, podremos acercar a nuestros doctores a donde te
              encuentres. <br /> <br />
              Con ello podremos escucharte y ver de la mejor manera tu caso
              clínico y brindarte nuestra opinión para la mejor solución de
              mismo.
            </p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 province-step">
            <h3>Gestión de permisos y documentación</h3>
            <p>
              Si a partir del servicio de telemedicina evaluamos que es
              necesario una intervención quirúrgica, podemos gestionar contigo
              todo lo necesario para llevar a cabo tu intervención quirúrgica y
              hospitalización en Lima.
              <br /> <br />
              En Lima nosotros nos encargaremos de gestionar todos los permisos
              de hospitalización necesarios. Todo con la finalidad de que tu
              única preocupación sea llegar, nosotros nos encargaremos del
              resto.
            </p>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 province-img">
            <img src={convenios} />
          </div>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 province-img">
            <img src={prestaciones} />
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 province-step">
            <h3>Recepción del paciente en Lima</h3>
            <p>
              Ya sea que la recepción del paciente sea vía terrestre o vía
              aérea, nosotros lo recibiremos con un vehículo ambulancia o unidad
              móvil para su inmediato traslado a la clínica donde se le
              realizará su respectiva operación.
              <br /> <br />
              Nuestro vehículo estará acompañado de nuestro personal con los
              implementos clínicos y de seguridad ideales para su traslado a
              nuestra clínica.
            </p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 province-step">
            <h3>Operación y hospitalización</h3>
            <p>
              Nuestro equipo de médicos estará esperando en nuestras sedes
              clínicas para la adecuada e inmediata intervención quirúrgica, con
              las mejores condiciones de infraestructura para llevar a cabo tu
              cirugía.
              <br /> <br />
              Una vez concluida la operación el paciente será hospitalizado en
              las instalaciones de la clínica. Dependiendo de cada caso o tipo
              de operación, los días de hospitalización pueden variar. Todo ello
              con la finalidad de monitorear la estabilidad del paciente.
            </p>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 province-img">
            <img src={provincia3} />
          </div>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 province-img">
            <img src={provincia4} />
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 province-step">
            <h3>Embarcación y rehabilitación</h3>
            <p>
              Una vez concluido el periodo de hospitalización, al paciente se le
              brindará un Kit de terapia física para iniciar la
              tele-rehabilitación desde la comodidad de su hogar. Esta etapa es
              importante dado que permitirá al paciente recuperar su movilidad
              de forma controlada y supervisada.
              <br /> <br />
              El paciente será trasladado al terminal terrestre o aéreo
              seleccionado por el paciente para su posterior embarcación y
              retorno a su casa.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
