import React from "react";
import "./styles.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import pacifico from "../../../img/about/pacifico-logo.png";
import positiva from "../../../img/about/lapositiva-logo.jpg";
import crecer from "../../../img/about/crecerseguros-logo.png";
import protecta from "../../../img/about/protecta-logo.svg";
import "@splidejs/react-splide/css";

export const SoatAbout = ({ display }) => {
  return (
    <div id="soat-about">
      <div className="soat-title">
        <h2>Convenios con las principales compañías aseguradoras en SOAT</h2>
      </div>
      <div className="soat-text">
        <p style={{ display: display }}>
          ArthroMeds Peru posee convenio con las clínicas más prestigiosas en el
          caso el paciente requiera algún procedimiento quirúrgico y/o
          hospitalización.
        </p>
      </div>
      <Splide options={{ pagination: false }}>
        <SplideSlide>
          <img
            className="splide-img-soat"
            style={{
              borderRadius: "0px",
              width: "26rem",
              height: "14rem",
              objectFit: "contain",
            }}
            src={pacifico}
            alt="Image 1"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            className="splide-img-soat"
            style={{
              borderRadius: "0px",
              width: "26rem",
              height: "14rem",
              objectFit: "contain",
            }}
            src={positiva}
            alt="Image 1"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            className="splide-img-soat"
            style={{
              borderRadius: "0px",
              width: "26rem",
              height: "14rem",
              objectFit: "contain",
            }}
            src={crecer}
            alt="Image 1"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            className="splide-img-soat"
            style={{
              borderRadius: "0px",
              width: "26rem",
              height: "14rem",
              objectFit: "contain",
            }}
            src={protecta}
            alt="Image 1"
          />
        </SplideSlide>
      </Splide>
    </div>
  );
};
