import { specialties } from "../data/specialties";

export function getSpecialties() {
  const specialtiesList = specialties;
  return specialtiesList;
}

export function filterSpecialties(idCategorySpecialty) {
  let filteredSpecialties = getSpecialties().filter(
    (el) => el.idCategorySpecialty === idCategorySpecialty
  );
  return filteredSpecialties;
}
