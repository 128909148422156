import react from "react";
import { CardRounded } from "../../../components/CardRounded";
import { Link } from "react-router-dom";
import "./styles.css";
import doc1 from "../../../img/team/dr-christian.jpg";
import doc2 from "../../../img/team/dr-cristian.jpg";
import doc3 from "../../../img/team/dr-santiago.jpg";

export const Team = () => {
  return (
    <div id="team">
      <div className="row justify-content-center align-items-center">
        <div className="col-sm-12 col-md-8 col-lg-4 col-xl-4 team-title">
          <h2>Mejores doctores de Traumatología en Lima - Perú</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <CardRounded
          docImg={doc1}
          docImgAlt="Dr. Christian Huamaní"
          docName="Dr. Christian Huamaní"
          specDesc="Experto en hombro y rodilla"
          docBtn="Conocer más"
          link={"https://youtu.be/exMh4mBKm2w"}
        />
        <CardRounded
          docImg={doc3}
          docImgAlt="Dr. Santiago Acuña"
          docName="Dr. Santiago Acuña"
          specDesc="Experto en rodilla, pie y tobillo"
          docBtn="Conocer más"
          link={"https://youtu.be/exMh4mBKm2w"}
        />
        <CardRounded
          docImg={doc2}
          docImgAlt="Dr. Cristian Meneses"
          docName="Dr. Cristian Meneses"
          specDesc="Experto en pie y tobillo"
          docBtn="Conocer más"
          link={"https://youtu.be/exMh4mBKm2w"}
        />
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 team-title">
          <Link to={"nosotros/equipo"}>
            <button className="main-btn btn-team grow">Ver equipo</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
