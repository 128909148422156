/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./styles.css";
import { FaChevronDown } from "react-icons/fa";
import logo from "../../../img/logo/logo.svg";
import { Link, NavLink } from "react-router-dom";

export const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-light">
      <div class="container-fluid">
        <Link className="navbar-brand" to="/">
          <img className="grow" src={logo} alt="arthromeds" />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto my-4 mb-lg-0">
            <li class="nav-item mx-5">
              <NavLink
                activeClassName="active"
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Inicio
              </NavLink>
            </li>
            <li class="nav-item dropdown mx-5">
              <a href="">
                <NavLink activeClassName="active" to={"nosotros"}>
                  <span className="drop-span">Nosotros</span>
                  <FaChevronDown size={15} color="#9fa5b2" />
                </NavLink>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="nosotros/equipo" class="dropdown-item">
                    Nuestro Equipo
                  </Link>
                </li>
                <li>
                  <Link to="nosotros/casos-clinicos" class="dropdown-item">
                    Casos clínicos
                  </Link>
                </li>
                <li>
                  <Link to="nosotros/convenios-clinicos" class="dropdown-item">
                    Convenios clínicos
                  </Link>
                </li>
              </ul>
            </li>
            <li class="nav-item mx-5">
              <NavLink
                activeClassName="active"
                className="nav-link"
                aria-current="page"
                to="servicios"
              >
                Servicios
              </NavLink>
            </li>
            <li class="nav-item dropdown mx-5">
              <a href="">
                <NavLink activeClassName="active" to={"especialidades"}>
                  <span className="drop-span">Especialidades</span>
                  <FaChevronDown size={15} color="#9fa5b2" />
                </NavLink>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/especialidades/rodilla">Rodilla</Link>
                </li>
                <li>
                  <Link to="/especialidades/pie-y-tobillo">Pie y tobillo</Link>
                </li>
                <li>
                  <Link to="/especialidades/columna">Columna</Link>
                </li>
                <li>
                  <Link to="/especialidades/mano-y-muneca">Mano y muñeca</Link>
                </li>
                <li>
                  <Link to="/especialidades/cadera">Cadera</Link>
                </li>
                <li>
                  <Link to="/especialidades/hombro">Hombro</Link>
                </li>
              </ul>
            </li>
            <li class="nav-item mx-5">
              <NavLink
                activeClassName="active"
                className="nav-link"
                aria-current="page"
                to="contacto"
              >
                Contacto
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
