import React from "react";
import "./styles.css";
import { FaStar, FaChevronRight } from "react-icons/fa";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import teddy from "../../../img/home/teddyromero.png";
import daniel from "../../../img/home/danielmarquez.png";
import miqueas from "../../../img/home/miqueas.png";
import yamilet from "../../../img/home/yamilet.png";
import richard from "../../../img/home/planocorto-richardcalcurian.png";
import javier from "../../../img/home/planocorto-javiergarcia.png";
import dimas from "../../../img/home/planocorto-dimas.png";
import paolo from "../../../img/home/planocorto-paoloballon.png";
import luis from "../../../img/home/planocorto-luisangel.png";

export const Testimonials = () => {
  return (
    <div id="testimonials">
      <div className="testimonials-title">
        <h2>Testimonios</h2>
        <p>Casos de recuperación y operaciones exitosas</p>
      </div>
      <Splide
        options={{
          perPage: 2,
          breakpoints: {
            640: {
              perPage: 1,
            },
            1024: {
              perPage: 1,
            },
            1440: {
              perPage: 2,
            },
          },
          rewind: true,
        }}
      >
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={teddy} alt="Teddy Romero" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Teddy Romero</h4>
              <p>Rodilla fracturada</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-1 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-1 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-1 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-1 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-1 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=Uq4R6aXrJT8"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={daniel} alt="Daniel Marquez" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Daniel Márquez</h4>
              <p>Alargamiento óseo</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=fEzyDP3GAgU"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={miqueas} alt="Miqueas Eli" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Miqueas Eli</h4>
              <p>Fractura de mano y muñeca</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=4XLUo1rk_kI"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={yamilet} alt="Yamilet Acosta" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Yamilet Acosta</h4>
              <p>Fractura de mano y tobillo</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=uAvfkWQ_d-E"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={richard} alt="Richard Calcurian" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Richard Calcurian</h4>
              <p>Fractura de cadera</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=uw4bZ-oQg5w"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={javier} alt="Javier García" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Javier García</h4>
              <p>Fractura de tibia</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=We5jwScjmDQ"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={dimas} alt="Sr. Dimas" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Sr. Dimas</h4>
              <p>Alargamiento óseo</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=R_fzwsXtzsg"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={paolo} alt="Paolo Ballón" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Paolo Ballón</h4>
              <p>Alargamiento óseo y reconstrucción</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=OQkGf3dRz7U"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img src={luis} alt="Luis Ángel" />
            </div>
            <div className="col-md-3 testimonial-name">
              <h4>Luis Ángel</h4>
              <p>Transporte óseo</p>
              <div className="row justify-content-center stars">
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
                <div className="col-sm-12 col-md-2">
                  <FaStar size={20} color="#ff9505" />
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-md-11 testimonial-link">
                  <a
                    href="https://www.youtube.com/watch?v=OUyYW7PVBzM"
                    target={"_blank"}
                  >
                    Ver testimonio <FaChevronRight color="#32a7da" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
};
