import react from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export const SpecialtyItem = ({ id, linkName, nombre, categoria, img }) => {
  return (
    <div className="card-container grow col-md-3 col-lg-3 col-xl-2">
      <Link to={`/especialidades/${linkName}`}>
        <div>
          <img src={img} alt={nombre} />
        </div>
        <div>
          <h5>{nombre}</h5>
          <h6>{categoria}</h6>
        </div>
      </Link>
    </div>
  );
};
