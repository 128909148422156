import react from "react";
import "./styles.css";
import phone from "../../../img/icons/phone.png";
import map from "../../../img/icons/map.png";
import time from "../../../img/icons/time.png";

export const Call = () => {
  return (
    <div id="call">
      <div className="call-title">
        <h2>¿Tienes alguna pregunta?</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4 col-lg-3 call-item">
          <img src={map} alt="mapa" />
          <h2 class="pt-4">Dirección</h2>
          <p class="pt-2">Av Sanchez Pinillos 188 - Cercado de Lima</p>
        </div>
        <div class="col-md-4 col-lg-3 call-item">
          <img src={time} alt="Horario arthromeds" />
          <h2 class="pt-4">Horario de atención</h2>
          <p class="pt-2">
            8:00 AM - 6:00 PM <br />
            Lunes - Sábado
          </p>
        </div>
        <div class="col-md-4 col-lg-3 call-item">
          <img src={phone} alt="Llámanos" />
          <h2 class="pt-4">Contáctanos</h2>
          <p class="pt-2">
            + (051) 424-6321 + (051) 960 080 073 programacion.citasmedicas
            @arthromedsport.pe
          </p>
        </div>
      </div>
    </div>
  );
};
