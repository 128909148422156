import react from "react";
import "./styles.css";
import logo from "../../../img/logo/logo.svg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(faFacebook, faInstagram, faYoutube);

export const Footer = () => {
  return (
    <footer>
      <div className="row justify-content-around">
        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 logo-box">
          <Link to={"/"}>
            <img className="grow" src={logo} alt="arthromeds" />
          </Link>
          <p className="footer-text">(+01) 424-6321</p>
          <p className="footer-text">(+51) 960-080-073</p>
          <p className="footer-text">Lun - Sab 8:00am - 6:00pm</p>
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 footer-box">
          <Link to={"nosotros"}>
            <h3 className="footer-title">Nosotros</h3>
          </Link>
          <Link to={"nosotros/equipo"}>
            <p className="footer-text">Nuestro equipo</p>
          </Link>
          <Link to={"nosotros/casos-clinicos"}>
            <p className="footer-text">Casos clínicos</p>
          </Link>
          <Link to={"nosotros/convenios-clinicos"}>
            <p className="footer-text">Convenios clínicos</p>
          </Link>
          <Link to={"nosotros"}>
            <p className="footer-text">H.M.C</p>
          </Link>
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 footer-box">
          <Link to={"servicios"}>
            <h3 className="footer-title">Servicios</h3>
          </Link>
          <Link to={"servicios"}>
            <p className="footer-text">Servicios clínicos</p>
          </Link>
          <Link to={"servicios"}>
            <p className="footer-text">Cirugía especializada</p>
          </Link>
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 footer-box">
          <Link to={"especialidades"}>
            <h3 className="footer-title">Especialidades</h3>
          </Link>
          <Link to={"especialidades/rodilla"}>
            <p className="footer-text">Rodilla</p>
          </Link>
          <Link to={"especialidades/pie-y-tobillo"}>
            <p className="footer-text">Pie y tobillo</p>
          </Link>
          <Link to={"especialidades/columna"}>
            <p className="footer-text">Columna</p>
          </Link>
          <Link to={"especialidades/mano-y-muneca"}>
            <p className="footer-text">Mano y muñeca</p>
          </Link>
          <Link to={"especialidades/cadera"}>
            <p className="footer-text">Cadera</p>
          </Link>
          <Link to={"especialidades/hombro"}>
            <p className="footer-text">Hombro</p>
          </Link>
        </div>
      </div>
      <hr />
      <div className="row justify-content-around">
        <div className="copyright col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <p>Copyright © 2022 Arthromeds | Hecho por Twinstudios</p>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="row justify-content-center">
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 social">
              <a
                href="https://www.facebook.com/clinicaarthromeds"
                target={"_blank"}
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={["fab", "facebook"]}
                  size="xl"
                />
              </a>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 social">
              <a
                href="https://www.instagram.com/clinicaarthromeds/?hl=es-la"
                target={"_blank"}
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={["fab", "instagram"]}
                  size="xl"
                />
              </a>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 social">
              <a
                href="https://www.youtube.com/channel/UCoRD78OxiTOBvvcGlLA_zWQ"
                target={"_blank"}
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={["fab", "youtube"]}
                  size="xl"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
