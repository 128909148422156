import React from "react";
import "./styles.css";
import teddy from "../../../img/testimonies/testimonio-teddy.png";
import daniel from "../../../img/testimonies/testimonio-danielmarquez.png";
import miqueas from "../../../img/testimonies/testimonio-miqueas.png";
import yamilet from "../../../img/testimonies/testimonio-yamilet.png";

export const TestimonialSpecialty = () => {
  return (
    <div id="testimonial-services">
      <div>
        <h2>Cambiamos y mejoramos vidas</h2>
      </div>
      <div className="row justify-content-center align-items-center item-testimonial">
        <div className="col-md-5">
          <a href="https://www.youtube.com/watch?v=uAvfkWQ_d-E" target="_blank">
            <img src={yamilet}></img>
          </a>
          <h3>Yamilet Acosta</h3>
          <p>Caso de fractura de tobillo y mano</p>
        </div>
        <div className="col-md-5">
          <a href="https://www.youtube.com/watch?v=fEzyDP3GAgU" target="_blank">
            <img src={daniel}></img>
          </a>
          <h3>Daniel Márquez</h3>
          <p>Alargamiento óseo</p>
        </div>
        <div className="col-md-5">
          <a href="https://www.youtube.com/watch?v=Uq4R6aXrJT8" target="_blank">
            <img src={teddy}></img>
          </a>
          <h3>Teddy Romero</h3>
          <p>Caso de rodilla fracturada</p>
        </div>
        <div className="col-md-5">
          <a href="https://www.youtube.com/watch?v=4XLUo1rk_kI" target="_blank">
            <img src={miqueas}></img>
          </a>
          <h3>Miqueas Eli</h3>
          <p>Caso fractura de mano y muñeca</p>
        </div>
      </div>
    </div>
  );
};
