import react from "react";
import "./styles.css";

export const CardRounded = ({
  docImg,
  docImgAlt,
  docName,
  specDesc,
  docBtn,
  link,
}) => {
  return (
    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 card-rounded">
      <img src={docImg} alt={docImgAlt} />
      <h5>{docName}</h5>
      <p>{specDesc}</p>
      <a href={link} target="_blank">
        <button className="second-btn btn-mob grow">{docBtn}</button>
      </a>
    </div>
  );
};
