import { CallToAction } from "../../components/CallToAction";
import { DoctorListContainer } from "../../components/DoctorListContainer";
import { SliderTeam } from "./SliderAbout";

export const TeamAbout = () => {
  return (
    <>
      <SliderTeam />
      <DoctorListContainer />
      <CallToAction
        title={"¡Agenda una cita con nosotros!"}
        text={
          "Comienza tu pronta recuperación de la mano del mejor equipo de traumatología en Lima."
        }
        buttonText={"Agendar una cita"}
        buttonLink={"../contacto"}
      />
    </>
  );
};
