export const services = [
  {
    id: 1,
    nombre: "Terapia física",
    linkName: "terapia-fisica",
    titulo: "Servicio de Terapia Física y Rehabilitación en Lima - Perú",
    idCategory: "11",
    idCategoryService: "2",
    categoria: "Servicios clínicos",
    desc1:
      "La terapia física y de rehabilitación es muy importante para tratar lesiones del aparato locomotor, ya sea desde un enguince simple de tobillo hasta grandes lesiones de cadera o rodilla. ",
    desc2:
      "Este tipo de tratamiento es recomendado para pacientes que fueron sometidos a intervenciones quirúrgicas traumatológicas (fractura, reconstrucciones por traumatismos, roturas ligamentarias) y de ortopedia (Cirugía de artoplastía total de cadera, rodilla y hombro, cirugía descompresiva de columna, correcciones de malformaciones de las extremidades inferiores).",
    img: "https://i.ibb.co/nQcmX5y/physical-therapy.png",
    img2: "https://i.ibb.co/9sr8zkN/terapiafisica1.jpg",
    img3: "https://i.ibb.co/SR14P2L/terapiafisica2.jpg",
    imgBanner: "https://i.ibb.co/sFbnKd5/banner-terapiafisica.png",
    imgBannerMob: "https://i.ibb.co/NVtxcJj/banner-terapiafisica-mobile.png",
    imgVideo: "https://i.ibb.co/GCqyvcG/testimonio-luisangel.png",
    nameTestimony: "Luis Ángel",
    descTestimony: "Caso de recuperación por transporte óseo",
    urlVideo: "https://www.youtube.com/watch?v=OUyYW7PVBzM",
  },
  {
    id: 2,
    nombre: "Neurología",
    linkName: "neurologia",
    titulo: "Neurología en Lima - Perú",
    idCategory: "12",
    idCategoryService: "2",
    categoria: "Servicios clínicos",
    desc1:
      "Un neurólogo es un médico que se especializa en el diagnóstico y el tratamiento de las enfermedades del cerebro y la médula espinal. En la clínica ARTHROMEDS contamos el mejor equipo de neurología, los cuales cuentan con herramientas modernas como Eletromiografías, procedimiento de diagnóstico que se utiliza para evaluar la salud de los músculos y las células nerviosas que los controlan (neuronas motoras).",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en neurología para ello.",
    img: "https://i.ibb.co/wccSqf6/brain.png",
    img2: "https://i.ibb.co/7gGDWPk/neurologia1.png",
    img3: "https://i.ibb.co/2k5MhmX/neurologia2.jpg",
    imgBanner: "https://i.ibb.co/251CYzp/banner-neurologia.jpg",
    imgBannerMob: "https://i.ibb.co/c89sxpb/banner-neurologia-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 3,
    nombre: "Reumatología",
    linkName: "reumatologia",
    titulo: "Reumatología en Lima - Perú",
    idCategory: "13",
    idCategoryService: "2",
    categoria: "Servicios clínicos",
    desc1:
      "La reumatología es una disciplina de la Medicina Interna que se especializa en el diagnóstico y tratamiento de enfermedades que afectan a las articulaciones, los músculos, tendones y huesos. Estas enfermedades pueden ser diversas y, generalmente, se expresan por dolor persistente e inflamación.",
    desc2:
      "Nos enfocaremos a lograr tu pronta recuperación. Tenemos el compromiso de ser totalmente transparente con nuestros pacientes, recomendando la necesidad de cirugía solo en el caso de que lo veamos estrictamente necesario.  Si vemos que el dolor puede tratarse con otro tipo de tratamiento, será el mejor camino a tomar, contamos con los especialistas en reumatología para ello.",
    img: "https://i.ibb.co/rt57pDN/physiotherapist.png",
    img2: "https://i.ibb.co/pPVc30P/reumatologia1.jpg",
    img3: "https://i.ibb.co/2vrnvY8/reumatologia2.jpg",
    imgBanner: "https://i.ibb.co/nmdMpn3/banner-reumatologia.jpg",
    imgBannerMob: "https://i.ibb.co/Ry8dh2d/banner-reumatologia-mobile.png",
    imgVideo: "https://i.ibb.co/R73jS8F/testimonio-anderson.png",
    nameTestimony: "Anderson",
    descTestimony: "Caso de alargamiento óseo",
    urlVideo: "https://www.youtube.com/watch?v=GFYOdsfG3q0",
  },
  {
    id: 4,
    nombre: "Ozonoterapia",
    linkName: "ozonoterapia",
    titulo: "Ozonoterapia en Lima - Perú",
    idCategory: "14",
    idCategoryService: "2",
    categoria: "Servicios clínicos",
    desc1:
      "El ozono es un gas medicinal que se lleva utilizando durante muchos años en el tratamiento de distintas patologías articulares, como en el caso de artrosis de rodilla, cadera, hombro, patologías tendinosas y en patologías discales.",
    desc2:
      "Deberá acudir a la consulta de Ozonoterapia cuando presente un dolor crónico, hernia o protrusión discal, dolor en las articulaciones o patologías degenerativas, y cuando desee mejorar el aporte de oxígeno a los tejidos y mejorar el sistema inmunitario.",
    img: "https://i.ibb.co/S3JygKP/human-body.png",
    img2: "https://i.ibb.co/3N9zydz/ozonoterapia1.jpg",
    img3: "https://i.ibb.co/R4LMBS9/ozonoterapia2.jpg",
    imgBanner: "https://i.ibb.co/JnQxnJn/banner-ozonoterapia.png",
    imgBannerMob: "https://i.ibb.co/Dtz0P5f/banner-ozonoterapia-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 5,
    nombre: "Alargamiento óseo",
    linkName: "alargamiento-oseo",
    titulo: "Operación de alargamiento óseo en Lima - Perú",
    idCategory: "5",
    idCategoryService: "3",
    categoria: "Cirugía especializada",
    desc1:
      "Los procedimientos de alargamiento o elongación ósea permiten aumentar la estatura de individuos con talla baja patológica, corregir deformidades óseas o diferencias de longitud entre los miembros del paciente. Este tipo de procedimientos es usualmente utilizado para recuperar  la longitud del hueso perdido, usualmente debido a accidentes o en situaciones de infección ósea. ",
    desc2:
      "El hueso tiene la capacidad de repararse cuando se produce una fractura. Esta característica puede aprovecharse para “engañar” al hueso y hacer que éste gane longitud a la vez que se cura tras una fractura que el cirujano crea de modo controlado. Es decir; el médico secciona el hueso y lo va estirando poco a poco con ayuda de algún dispositivo a medida que éste se va curando. En este artículo por la MBA Surgery Empowerment de España sobre cómo se alargan las extremidades se detalla el método por el que se logra llevar a cabo este procedimiento.",
    img: "https://i.ibb.co/HHZv36B/leg.png",
    img2: "https://i.ibb.co/N1Ks9wX/procedimiento.jpg",
    img3: "https://i.ibb.co/dcw9Mkk/fijador.jpg",
    imgBanner: "https://i.ibb.co/3YrFGpT/banner-alargamientooseo.png",
    imgBannerMob: "https://i.ibb.co/5LgKCY5/banner-elongacion-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 6,
    nombre: "Elongación ósea estética",
    linkName: "elongacion-osea-estetica",
    idCategory: "6",
    idCategoryService: "3",
    categoria: "Cirugía especializada",
    desc1:
      "Los procedimientos de alargamiento o elongación ósea permiten aumentar la estatura de individuos con talla baja patológica, corregir deformidades óseas o diferencias de longitud entre los miembros del paciente. Este tipo de procedimientos es usualmente utilizado para recuperar  la longitud del hueso perdido, usualmente debido a accidentes o en situaciones de infección ósea. ",
    desc2:
      "El hueso tiene la capacidad de repararse cuando se produce una fractura. Esta característica puede aprovecharse para “engañar” al hueso y hacer que éste gane longitud a la vez que se cura tras una fractura que el cirujano crea de modo controlado. Es decir; el médico secciona el hueso y lo va estirando poco a poco con ayuda de algún dispositivo a medida que éste se va curando. En este artículo por la MBA Surgery Empowerment de España sobre cómo se alargan las extremidades se detalla el método por el que se logra llevar a cabo este procedimiento.",
    img: "https://i.ibb.co/MMzYNf2/elongaci-n.png",
    img2: "https://i.ibb.co/gF6F1XK/alargamientooseoestetico1.jpg",
    img3: "https://i.ibb.co/dcw9Mkk/fijador.jpg",
    imgBanner: "    https://i.ibb.co/3YrFGpT/banner-alargamientooseo.png",
    imgBannerMob: "https://i.ibb.co/5LgKCY5/banner-elongacion-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 7,
    nombre: "Corrección de deformidades",
    linkName: "correccion-de-deformidades",
    idCategory: "7",
    idCategoryService: "3",
    categoria: "Cirugía especializada",
    desc1:
      "Aunque las piernas arqueadas son más comunes en los niños, también pueden desarrollarse en adolescentes y adultos. Aparte de los aspectos estéticos, las piernas arqueadas pueden causar todo tipo de problemas más adelante en la vida, lo cual es una buena razón para ver a un cirujano ortopédico para una evaluación y posible corrección. Aquí está todo lo que necesita saber sobre las piernas arqueadas.",
    desc2:
      "En muchos casos, una inspección visual de las piernas es todo lo que se necesita para  hacer el diagnostico. Las radiografías y las resonancias magnéticas pueden producir una imagen más detallada del problema y su gravedad.",
    img: "https://i.ibb.co/S5WQWfC/deformidades.png",
    img2: "https://i.ibb.co/KGyznMF/deformidades1.jpg",
    img3: "https://i.ibb.co/WfTXVJ8/deformidades2.jpg",
    imgBanner: "https://i.ibb.co/8bcvyy2/banner-deformidades.jpg",
    imgBannerMob: "https://i.ibb.co/QbdZQnM/banner-deformidades-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 8,
    nombre: "Artroscopía",
    linkName: "atroscopia",
    idCategory: "8",
    idCategoryService: "3",
    categoria: "Cirugía especializada",
    desc1:
      "La artroscopia es un procedimiento para diagnosticar y tratar problemas en las articulaciones. Un cirujano inserta un conducto delgado conectado a una cámara de video de fibra óptica a través de una pequeña incisión del tamaño de un ojal. La imagen dentro de la articulación se transmite a un monitor de video de alta definición.",
    desc2:
      "Una de las principales ventajas de la artroscopia para el paciente radica en que, para llevarla a cabo, se necesita practicar incisiones realmente pequeñas. Al tratarse de una técnica mínimamente invasiva, la estancia hospitalaria es menor y la recuperación es mucho más rápida y las complicaciones de la artroscopia son mínimas.",
    img: "https://i.ibb.co/cNsCg1p/diagnostic.png",
    img2: "https://i.ibb.co/b6tKZ9P/artroscopia1.jpg",
    img3: "https://i.ibb.co/QJhn3r8/artroscopia2.jpg",
    imgBanner: "https://i.ibb.co/4ZQymXx/banner-artroscopia.jpg",
    imgBannerMob: "https://i.ibb.co/fdVGTb1/banner-artroscopia-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 9,
    nombre: "Reemplazo articular",
    linkName: "reemplazo-articular",
    idCategory: "9",
    idCategoryService: "3",
    categoria: "Cirugía especializada",
    desc1:
      "Es una cirugía para reemplazar una articulación por una articulación artificial. Esta articulación artificial se llama prótesis. Las prótesis articulares mejoran la capacidad funcional de pacientes con dolor articular.La cirugía protésica es la solución para una articulación estropeada e irreparable, principalmente por desgaste de la misma (artrosis) pero también por lesiones traumáticas (fracturas subcapitales del anciano). Es una cirugía con excelentes resultados, particularmente en la cadera y rodilla y, por ello, es la técnica quirúrgica más empleada en esta articulación.",
    desc2:
      "El reemplazo de rodilla es una cirugía para personas con daños graves en la rodilla. El reemplazo de la rodilla puede aliviar el dolor y permitirle ser más activo. El médico puede recomendarle el procedimiento si tiene dolor en la rodilla y si otros tratamientos ya no son efectivos.Cuando se somete a un reemplazo total de rodilla, el cirujano elimina el cartílago y el hueso dañado de la superficie de la articulación de la rodilla y los reemplaza por superficies artificiales de metal y plástico.",
    img: "https://i.ibb.co/80nF6tV/knee-joint.png",
    img2: "https://i.ibb.co/fN9jFB1/reemplazoarticular1.jpg",
    img3: "https://i.ibb.co/b2nbfYC/reemplazoarticular2.jpg",
    imgBanner: "https://i.ibb.co/cb7Hxjw/banner-reemplazoarticular.png",
    imgBannerMob:
      "https://i.ibb.co/J2ryr7k/banner-reemplazoarticular-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
  {
    id: 10,
    nombre: "Infección ósea",
    linkName: "infeccion-osea",
    idCategory: "10",
    idCategoryService: "3",
    categoria: "Cirugía especializada",
    desc1:
      "La infección ósea (ostelomilitis) es una infección que afecta al hueso, causada generalmente por bacterias, mico bacterias u hongos.",
    desc2:
      "Aunque en algún momento se consideraba incurable, en la actualidad la osteomielitis puede tratarse con éxito. La mayoría de las personas necesitan someterse a una cirugía para extirpar las zonas del hueso que murieron. Después de la cirugía, generalmente se necesitan antibióticos fuertes por vía intravenosa.",
    img: "https://i.ibb.co/J7n22hy/research.png",
    img2: "https://i.ibb.co/5RTSjGP/infeccionosea1.jpg",
    img3: "https://i.ibb.co/RY0qVCc/infeccionosea2.jpg",
    imgBanner: "https://i.ibb.co/GT9Jctx/banner-infeccionosea.png",
    imgBannerMob: "https://i.ibb.co/jvnKG42/banner-infeccionosea-mobile.png",
    imgVideo: "https://i.ibb.co/2ZGnYDB/testimonio-danielmarquez.png",
    nameTestimony: "Daniel Márquez",
    descTestimony: "Caso de alargamiento óseo estético",
    urlVideo: "https://www.youtube.com/watch?v=fEzyDP3GAgU&t",
  },
];
