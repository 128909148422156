import React from "react";
import { Helmet } from "react-helmet";
import { Slider } from "./Slider";
import { Specialties } from "./Specialties";
import { Target } from "./Target";
import { Tasks } from "./Tasks";
import { Team } from "./Team";
import { Testimonials } from "./Testimonials";

export const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Arthromeds | La principal clínica de Traumatología en el Perú
        </title>
      </Helmet>
      <Slider />
      <Tasks />
      <Target />
      <Specialties />
      <Team />
      <Testimonials />
    </>
  );
};
