import React from "react";
import { Team } from "../../pages/Home/Team";
import { Banner } from "../Banner";
import { CallToAction } from "../CallToAction";
import { CollapseListContainer } from "../CollapseListContainer";
import { CounterItem } from "../CounterItem";
import "./styles.css";

export const ItemDetail = ({
  id,
  nombre,
  titulo,
  idCategory,
  categoria,
  desc1,
  desc2,
  precio,
  img,
  img2,
  img3,
  imgBanner,
  imgBannerMob,
  imgVideo,
  nameTestimony,
  descTestimony,
  urlVideo,
}) => {
  const nameItem = nombre;
  return (
    <div id="item-detail">
      <Banner img={imgBanner} imgMob={imgBannerMob} display={"none"} />
      <div id="item-detail-section">
        <div className="item-detail-title">
          <h2>{titulo}</h2>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 detail-img">
            <img src={img2} />
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 detail-desc1">
            <p>{desc1}</p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center item-desc">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 detail-desc2 order2">
            <p>{desc2}</p>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 detail-img order1">
            <img src={img3} />
          </div>
        </div>
      </div>
      {nameItem === "Alargamiento óseo" && (
        <div id="counter-section" className="row justify-content-center">
          <CounterItem
            title={"Alargamientos reconstructivos"}
            counterStart={"0"}
            counterEnd={"2335"}
            counterDuration={"1"}
          />
          <CounterItem
            title={"Alargamientos estéticos"}
            counterStart={"0"}
            counterEnd={"121"}
            counterDuration={"1"}
          />
        </div>
      )}
      <Team />
      <CallToAction
        title={"¡Agenda una cita con nosotros!"}
        text={
          "Comienza tu pronta recuperación de la mano del mejor equipo de traumatología en Lima."
        }
        buttonText={"Agendar una cita"}
        buttonLink={"/"}
      />
      <div id="testimonial-services">
        <div>
          <h2>Cambiamos y mejoramos vidas</h2>
        </div>
        <div className="row justify-content-center align-items-center item-testimonial-detail">
          <div className="col-sm-12 col-md-8 col-lg-5">
            <a href={urlVideo} target="_blank">
              <img src={imgVideo}></img>
            </a>
            <h3>{nameTestimony}</h3>
            <p>{descTestimony}</p>
          </div>
        </div>
      </div>
      <CollapseListContainer idCategory={idCategory} />
    </div>
  );
};
