import react from "react";
import { Helmet } from "react-helmet";
import { CallToAction } from "../../components/CallToAction";
import { SpecialtyListContainer } from "../../components/SpecialtyListContainer";
import { SliderDocSpecialty } from "./SliderDocSpecialty";
import { SliderSpecialty } from "./SliderSpecialty";
import { TestimonialSpecialty } from "./TestimonialSpecialty";

export const Specialties = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arthromeds | Traumatólogos especialistas en el Perú</title>
      </Helmet>
      <SliderSpecialty />
      <SpecialtyListContainer
        titleStart={"Conoce nuestras"}
        titleEnd={"especialidades"}
        btnOne={"Ver todos"}
        btnTwo={"Especialidades"}
        btnThree={"Servicios clínicos"}
      />
      <SliderDocSpecialty />
      <CallToAction
        title={"¡Agenda una cita con nosotros!"}
        text={
          "Comienza tu pronta recuperación de la mano del mejor equipo de traumatología en Lima."
        }
        buttonText={"Agendar una cita"}
        buttonLink={"/"}
      />
      <TestimonialSpecialty />
    </>
  );
};
