import react from "react";
import "./styles.css";
import knee from "../../../img/home/knee.png";
import ankle from "../../../img/home/ankle.png";
import spine from "../../../img/home/spine.png";
import wrist from "../../../img/home/wrist.png";
import pelvis from "../../../img/home/pelvis.png";
import shoulder from "../../../img/home/shoulder.png";
import { Link } from "react-router-dom";

export const Specialties = () => {
  return (
    <div id="specialties">
      <div className="row justify-content-center align-items-center">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 specialty-title">
          <h2>Especialidades</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 specialty-img">
          <Link to={"especialidades/rodilla"}>
            <img className="grow" src={knee} alt="rodilla" />
          </Link>
          <h4>Rodilla</h4>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 specialty-img">
          <Link to={"especialidades/pie-y-tobillo"}>
            <img className="grow" src={ankle} alt="tobillo" />
          </Link>
          <h4>Pie y tobillo</h4>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 specialty-img">
          <Link to={"especialidades/columna"}>
            <img className="grow" src={spine} alt="columna vertebral" />
          </Link>
          <h4>Columna</h4>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 specialty-img">
          <Link to={"especialidades/mano-y-muneca"}>
            <img className="grow" src={wrist} alt="mano y muñeca" />
          </Link>
          <h4>Mano y muñeca</h4>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 specialty-img">
          <Link to={"especialidades/cadera"}>
            <img className="grow" src={pelvis} alt="cadera" />
          </Link>
          <h4>Cadera</h4>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 specialty-img">
          <Link to={"especialidades/hombro"}>
            <img className="grow" src={shoulder} alt="hombro" />
          </Link>
          <h4>Hombro</h4>
        </div>
      </div>
    </div>
  );
};
