import react from "react";
import "./styles.css";
import specialists from "../../../img/clinica-especialista.jpg";
import services from "../../../img/servicios.jpg";
import doc from "../../../img/about/doctorhuamani-nosotros.jpg";
import { Link } from "react-router-dom";

export const DetailsAbout = () => {
  return (
    <div id="details-about">
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 section-specialists">
          <img src={specialists} alt="especialistas" />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 section-specialists">
          <h2>Especialistas en alargamiento óseo</h2>
          <p>
            En ARTHROMEDS somos pioneros en el procedimiento de alargamiento
            óseo en el Perú. Nuestra amplia experiencia corrigiendo dismetrías
            de extremidad, como ayudando a las personas a aumentar de estatura,
            nos hacen la clínica LÍDER en este tipo de procedimientos.
          </p>
          <Link to={"../servicios/5"}>
            <button className="main-btn grow">Ver más</button>
          </Link>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 section-specialists">
          <h2>Conoce al Dr. Christian Huamaní</h2>
          <p>
            Nuestro Director General de la clínica ARTHROMEDS nos habla un poco
            sobre si y el objetivo principal y valores que nos motivan a dar lo
            mejor de nosotros por la recuperación de nuestros pacientes.
          </p>
          <a href="https://youtu.be/kBXcjXKr-dY" target={"_blank"}>
            <button className="main-btn grow">Ver más</button>
          </a>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 section-specialists text-end">
          <img src={doc} alt="doctor huamaní" />
        </div>
      </div>
    </div>
  );
};
