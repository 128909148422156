import react from "react";
import { Helmet } from "react-helmet";
import { CallToAction } from "../../components/CallToAction";
import { CareAbout } from "./CareAbout";
import { ConventionAbout } from "./ConventionAbout";
import { DetailsAbout } from "./DetailsAbout";
import { MedicineAbout } from "./MedicineAbout";
import { PatentedAbout } from "./PatentedAbout";
import { SliderAbout } from "./SliderAbout";
import { SoatAbout } from "./SoatAbout";

export const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arthromeds | Mejores doctores de Traumatología en el Perú</title>
      </Helmet>
      <SliderAbout />
      <DetailsAbout />
      <CareAbout />
      <ConventionAbout />
      <PatentedAbout />
      <CallToAction
        title={"¡Agenda una cita con nosotros!"}
        text={
          "Comienza tu pronta recuperación de la mano del mejor equipo de traumatología en Lima."
        }
        buttonText={"Agendar una cita"}
        buttonLink={"../contacto"}
      />
      <MedicineAbout />
      <SoatAbout display={"none"} />
    </>
  );
};
