import React from "react";
import { DoctorList } from "../DoctorList";
import { doctors } from "../../data/doctors";
import "./styles.css";

export const DoctorListContainer = () => {
  return (
    <div id="doctor-list-container">
      <DoctorList doctors={doctors} />
    </div>
  );
};
