import react, { useEffect } from "react";
import "./styles.css";
import PureCounter from "@srexi/purecounterjs";

export const CounterItem = ({
  title,
  counterStart,
  counterEnd,
  counterDuration,
}) => {
  useEffect(() => {
    const pure = new PureCounter();
  });

  return (
    <div id="counter-item" className="col-md-4">
      <span
        data-purecounter-start={counterStart}
        data-purecounter-end={counterEnd}
        data-purecounter-duration={counterDuration}
        data-purecounter-separator="true"
        className="purecounter"
      ></span>
      <h5>{title}</h5>
    </div>
  );
};
