import react from "react";
import "./styles.css";
import { CardItem } from "../../components/CardItem";

export const ItemList = ({ items }) => {
  return (
    <div id="item-list" className="row justify-content-center">
      {/* <ItemFilter /> */}
      {items.map((el) => (
        <CardItem key={el.id} {...el} />
      ))}
    </div>
  );
};
