import react from "react";
import "./styles.css";
import { SpecialtyItem } from "../../components/SpecialtyItem";

export const SpecialtyList = ({ items }) => {
  return (
    <div id="item-list" className="row justify-content-center">
      {/* <ItemFilter /> */}
      {items.map((el) => (
        <SpecialtyItem key={el.id} {...el} />
      ))}
    </div>
  );
};
