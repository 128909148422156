import react from "react";
import "./styles.css";

export const Maps = (props) => {
  const {
    iframeSource = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.932604771162!2d-77.04602808536484!3d-12.04815809146635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9cf9e0ba1e1%3A0xb4048560432f6c6f!2sArthromeds%20cl%C3%ADnica%20de%20traumatolog%C3%ADa%20y%20terapia%20f%C3%ADsica!5e0!3m2!1ses-419!2spe!4v1659327719985!5m2!1ses-419!2spe" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
  } = props;
  return (
    <div id="map">
      <div className="map-title">
        <h2>Encuéntranos en Maps</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 map-item">
          <div dangerouslySetInnerHTML={{ __html: iframeSource }}></div>
        </div>
      </div>
    </div>
  );
};
